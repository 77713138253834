import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { topshotsAPI } from './api/api';
import cartReducer from '../cart/cart.slice';
import montageReducer from '../cart/montage.slice';
import modalReducer from '../modal/modalSlice';
import authReducer from '../login/authSlice';
import navReducer from '../header/nav/navSlice';
import favouritesReducer from '../slider/favourites.slice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['modal', topshotsAPI.reducerPath],
};
const rootReducer = combineReducers({
  [topshotsAPI.reducerPath]: topshotsAPI.reducer,
  cart: cartReducer,
  modal: modalReducer,
  auth: authReducer,
  favourites: favouritesReducer,
  montage: montageReducer,
  nav: navReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(topshotsAPI.middleware),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
