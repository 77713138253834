import { useParams } from 'react-router-dom';
import {
  Breadcrumbs,
  galleryCrumbToDisplayCrumb,
} from '../../breadcrumbs/breadcrumbs';
import { GalleryPage } from '../../gallery-page/gallery-page';
import styles from './year-page.module.css';
import { useGetCalendarYearPageQuery } from '../../app/api/pages';
import { Button } from '../../button/button';
import { ButtonTier } from '../../button/button.types';
import { Icon } from '../../svgs';
import { LinkButton } from '../../button/link-button';
import { PageTitle } from '../page-title/page-title';
import { Spinner } from '../../spinner/spinner';

export const YearPage = () => {
  const { year } = useParams<{ year: string }>();
  const { data, isLoading } = useGetCalendarYearPageQuery({
    year: +(year ?? '2023'),
  });
  const crumbs = [
    { display: 'Gallery', link: '/' },
    ...(data?.crumbs?.map(galleryCrumbToDisplayCrumb) ?? []),
    { display: data?.title ?? '', link: '' },
  ];
  return (
    <div className={styles.yearPage}>
      <PageTitle
        title={data?.title}
        backLink={{
          to: crumbs[crumbs.length - 2].link,
          label: crumbs[crumbs.length - 2].display,
        }}
        crumbs={crumbs}
      />
      {isLoading && <Spinner />}
      {!isLoading && data && (
        <GalleryPage
          linkPrefix={{
            month: `/year/${year}`,
          }}
          {...data}
        />
      )}
    </div>
  );
};
