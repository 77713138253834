import { useFormik } from 'formik';
import { ErrorText } from '../forms/error-text';
import { FormGroup } from '../forms/form-group';
import * as Yup from 'yup';
import styles from './login.module.css';
import { Button } from '../button/button';
import { ButtonTier } from '../button/button.types';
import { ModalContent, close, open } from '../modal/modalSlice';
import { useAppDispatch } from '../app/hooks';
import { setToken } from './authSlice';
import { useLoginMutation } from '../app/api/api';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
});

export const Login = ({
  redirect,
  hasReset,
  resetEmail,
}: {
  redirect?: string;
  hasReset?: boolean;
  resetEmail?: string;
}) => {
  const dispatch = useAppDispatch();
  const [login, { isLoading }] = useLoginMutation();
  const nav = useNavigate();
  const [error401, setError401] = useState<boolean>(false);
  const { getFieldProps, isSubmitting, touched, errors, handleSubmit } =
    useFormik({
      initialValues: {
        email: resetEmail ?? '',
        password: '',
      },
      enableReinitialize: true,
      validationSchema: LoginSchema,
      onSubmit: async (values, { setSubmitting }) => {
        setError401(false);
        const result = await login(values);
        if ('error' in result) {
          console.error(result.error);
          setError401(true);
          return;
        } else if ('data' in result) {
          dispatch(setToken(result.data.token));
        }
        setSubmitting(false);
        dispatch(close());
        if (redirect) {
          nav(redirect);
        }
      },
    });

  return (
    <form onSubmit={handleSubmit}>
      {error401 && (
        <ErrorText>
          There was a problem with your email or password. Please try again.
        </ErrorText>
      )}
      {hasReset && (
        <p>
          Your password has been reset. Please login with your new password.
        </p>
      )}
      <FormGroup className={styles.email}>
        <label htmlFor="email">Email</label>
        <input type="email" id="email" {...getFieldProps('email')} />
        {touched.email && errors.email ? (
          <ErrorText>{errors.email}</ErrorText>
        ) : null}
      </FormGroup>
      <FormGroup className={styles.password}>
        <label htmlFor="password">Password</label>
        <input type="password" id="password" {...getFieldProps('password')} />
        {touched.password && errors.password ? (
          <ErrorText>{errors.password}</ErrorText>
        ) : null}
      </FormGroup>
      <FormGroup buttons={true}>
        <Button type="submit" disabled={isSubmitting}>
          Login
        </Button>
        <Button
          tier={ButtonTier.Secondary}
          type="button"
          onClick={() => {
            dispatch(close());
          }}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
      </FormGroup>
      <FormGroup>
        <Button
          tier={ButtonTier.Quaternary}
          onClick={() => {
            dispatch(
              open({
                title: 'Forgotten Password',
                contentKey: ModalContent.ForgottenPassword,
                contentPayload: {},
              })
            );
          }}
        >
          Forgotten your password?
        </Button>
      </FormGroup>
      <FormGroup>
        <Button
          tier={ButtonTier.Quaternary}
          onClick={() => {
            dispatch(
              open({
                title: 'Register',
                contentKey: ModalContent.Register,
                contentPayload: {
                  redirect: '/account/',
                },
              })
            );
          }}
        >
          Don't have an account yet? Register now
        </Button>
      </FormGroup>
    </form>
  );
};
