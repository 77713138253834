import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { RootState } from '../app/store';

export interface ModalState {
  isOpen: boolean;
  title: string;
  contentKey: ModalContent;
  contentPayload?: any;
  backgroundClose: boolean;
}

export enum ModalContent {
  Login = 'LOGIN',
  ForgottenPassword = 'FORGOTTEN_PASSWORD',
  ResetPassword = 'RESET_PASSWORD',
  Register = 'REGISTER',
  Empty = 'EMPTY',
  Cart = 'CART',
  AddToCart = 'ADD_TO_CART',
  AddToCartMultiple = 'ADD_TO_CART_MULTIPLE',
  StartMontage = 'START_MONTAGE',
}

const initialState: ModalState = {
  isOpen: false,
  title: '',
  contentKey: ModalContent.Empty,
  backgroundClose: true,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    open: (
      state,
      action: PayloadAction<{
        title: string;
        contentKey: ModalContent;
        contentPayload?: any;
        backgroundClose?: boolean;
      }>
    ) => {
      state.title = action.payload.title;
      state.contentKey = action.payload.contentKey;
      state.contentPayload = action.payload.contentPayload;
      state.backgroundClose = action.payload.backgroundClose ?? true;
      state.isOpen = true;
    },
    close: (state) => {
      state.isOpen = false;
    },
  },
});

export const selectModal = (state: RootState) => state.modal;

export const { open, close } = modalSlice.actions;

export default modalSlice.reducer;
