import classNames from 'classnames';
import { Breadcrumbs } from '../../breadcrumbs/breadcrumbs';
import { ButtonTier } from '../../button/button.types';
import { LinkButton } from '../../button/link-button';
import { Icon } from '../../svgs';
import styles from './page-title.module.css';
import { PageTitleProps } from './page-title.types';

export const PageTitle = ({
  title,
  backLink,
  crumbs,
  className,
}: PageTitleProps) => (
  <div className={classNames(styles.pageTitle, className)}>
    {backLink && (
      <LinkButton
        className={styles.backLink}
        to={backLink.to}
        iconLeft={Icon.chevronLeft}
        tier={ButtonTier.Secondary}
      >
        {backLink?.label}
      </LinkButton>
    )}
    {title && <h1 className={styles.title}>{title}</h1>}
    {crumbs && <Breadcrumbs className={styles.crumbs} crumbs={crumbs} />}
  </div>
);
