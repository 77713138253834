import { useState } from 'react';
import { Button } from '../button/button';
import styles from './quant.module.css';
import { ButtonTier } from '../button/button.types';
import { Icon } from '../svgs';
import classNames from 'classnames';

export const Quant = ({
  onChange,
  includeLabel = true,
  initialQuantity,
  min = 1,
}: {
  initialQuantity?: number;
  includeLabel?: boolean;
  min?: number;
  onChange?: (newQuantity: number) => void;
}) => {
  const [quantity, setQuantity] = useState<number>(initialQuantity ?? 1);
  const handleChange = (newQuantity: number) => {
    setQuantity(newQuantity);
    if (onChange) {
      onChange(newQuantity);
    }
  };

  return (
    <div
      className={classNames(styles.quantity, {
        [styles['quantity--nolabel']]: !includeLabel,
      })}
    >
      {includeLabel && <label className={styles.qntLabel}>Qnt:</label>}
      <Button
        onClick={() => {
          handleChange(Math.max(quantity - 1, min));
        }}
        tier={ButtonTier.Tertiary}
        iconLeft={Icon.decreaseArrow}
        className={styles.decreaseButton}
      ></Button>
      <input
        onChange={(evt) => {
          handleChange(Number(evt.target.value));
        }}
        type="number"
        value={quantity}
      />
      <Button
        onClick={() => {
          handleChange(quantity + 1);
        }}
        tier={ButtonTier.Tertiary}
        className={styles.increaseButton}
        iconRight={Icon.increaseArrow}
      ></Button>
    </div>
  );
};
