import styles from './spinner.module.css';

export const Spinner = ({
  size = 'medium',
  color = 'standard',
  style,
}: {
  size?: 'small' | 'medium' | 'large';
  color?: 'standard' | 'inverted';
  style?: React.CSSProperties;
}) => (
  <div
    style={style}
    className={`${styles.spinner} ${styles[`spinner--${size}`]} ${
      styles[`spinner--color-${color}`]
    }`}
  ></div>
);
