import styles from './copyright-policy.module.css';

export const CopyrightPolicy = () => {
  return (
    <div className={styles.copyright}>
      <h2 className={styles.tac}>
        All images on our website and galleries are covered by the copyright act
        of 1988
      </h2>
      <p>
        Photographs that have been purchased from Top Shots Photography may be
        used for; Personal advertising, society journal picture galleries,
        personal websites, Facebook, Twitter and Instagram.
      </p>
      <p>
        Wherever and whenever a picture is reproduced it must be referenced to{' '}
        <a className={styles.link} href="https://www.topshots.org.uk">
          Topshots Photography - www.topshots.org.uk
        </a>
        . The responsibility lies with the publisher to check that permission
        has been granted for reproduction of all images.
      </p>
      <p>
        Any duplication, in any form, of unpurchased images is copyright
        infringement and strictly forbidden.
      </p>
      <p>
        Please remember copyright infringement is something that we take
        seriously and is legally theft.
      </p>
      <p>
        <span className="green">Top Shots Photography</span> own the copyright
        of all images on this site. If you would like to purchase the copyright
        of an image, please go to our{' '}
        <a className={styles.link} href="https://www.topshots.org.uk/Contact/">
          Contact page
        </a>
        .
      </p>
    </div>
  );
};
