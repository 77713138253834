import { useParams } from 'react-router-dom';
import {
  Breadcrumbs,
  galleryCrumbToDisplayCrumb,
} from '../../breadcrumbs/breadcrumbs';
import { GalleryPage } from '../../gallery-page/gallery-page';
import styles from './event-page.module.css';
import { useGetEventPageQuery } from '../../app/api/pages';
import { PageTitle } from '../page-title/page-title';
import { Spinner } from '../../spinner/spinner';
import { EventLoginPage } from './event-login';

export const EventPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, error, isError } = useGetEventPageQuery({
    eventId: id ?? '',
  });

  const crumbs = [
    { display: 'Gallery', link: '/' },
    ...(data?.crumbs?.map(galleryCrumbToDisplayCrumb) ?? []),
    { display: data?.title ?? '', link: '' },
  ];
  if (isError && (error as any).data === 'Unauthorized') {
    return <EventLoginPage eventId={id!} />;
  }
  return (
    <div className={styles.page}>
      <PageTitle
        title={data?.title}
        backLink={{
          to: crumbs[crumbs.length - 2].link,
          label: crumbs[crumbs.length - 2].display,
        }}
        crumbs={crumbs}
      />
      {isLoading && <Spinner />}
      {!isLoading && data && (
        <GalleryPage
          linkPrefix={{
            album: `/event/${id}`,
          }}
          {...data}
        />
      )}
    </div>
  );
};
