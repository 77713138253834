import { IEvent, IProduct } from '@topshots/core-models';
import { useState } from 'react';
import { Button } from '../../button/button';
import { ButtonIntent, ButtonTier } from '../../button/button.types';
import { Currency } from '../../currency/currency';
import styles from './product-list.module.css';
import { ProductListProps } from './product-list.types';
import { useAppSelector } from '../../app/hooks';
import { selectCartITems } from '../../cart/cart.slice';
import { selectProductQuantity } from '../../cart/cart.slice';

export const ProductList = ({
  products,
  event,
  onAddToCart,
}: ProductListProps) => {
  return (
    <ul className={styles.productList}>
      {products?.map((p, i) => (
        <ProductItem
          key={`pi_${i}`}
          onAddToCart={onAddToCart}
          event={event}
          product={p}
        />
      ))}
    </ul>
  );
};

export const ProductItem = ({
  product,
  event,
  onAddToCart,
}: {
  product: IProduct;
  event: IEvent;
  onAddToCart?: (product: IProduct) => void;
}) => {
  const quantity = useAppSelector(
    selectProductQuantity(product._id.toString())
  );

  let price = { discounted: false, from: product.price, to: product.price };

  const eventPrice = event.pricingOverrides.find(
    (p) => p.product_id.toString() === product._id.toString()
  );
  if (eventPrice) {
    price.from = eventPrice.price;
    price.to = eventPrice.price;
  }
  switch (product.pricingModel) {
    case 'tiered':
      // eslint-disable-next-line no-case-declarations
      const tier = product.priceTiers.find(
        (tp) =>
          tp.minVolume <= quantity + 1 &&
          (tp.maxVolume == null || tp.maxVolume >= quantity + 1)
      );
      price = {
        discounted: tier?.price !== product.priceTiers[0].price,
        from: product.priceTiers[0].price,
        to: tier?.price ?? product.price,
      };
      break;
  }

  return (
    <li key={product._id.toString()} className={styles.product}>
      <div>
        <p>{product.name}</p>
        {product.pricingModel === 'tiered' && (
          <p className={styles.multibuy}>
            <strong className={styles.multibuyTitle}>Multibuy discount:</strong>

            {/* Buy 2 or more and get 10% off */}
            {product.priceTiers
              .filter((t) => t.minVolume > 1)
              .map((tier, i) => (
                <>
                  <br />
                  <span key={i}>
                    Buy {tier.minVolume} for{' '}
                    <Currency value={tier.price * tier.minVolume} />
                  </span>
                </>
              ))}
          </p>
        )}
        {/* 
        {product.pricingModel === 'tiered' && (
          <table className={styles.tierTable}>
            <tbody>
              
            </tbody>
          </table>
        )} */}
      </div>
      <div
        className={`${styles.currency} ${
          product.pricingModel === 'tiered' ? styles.withTiers : ''
        }`}
      >
        {price.discounted && (
          <Currency className={styles.currencyDiscounted} value={price.from} />
        )}
        <Currency value={price.to} />
      </div>
      <Button
        onClick={() => {
          onAddToCart?.(product);
        }}
      >
        Add to Cart
      </Button>
    </li>
  );
};
