export const ChevronLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="none"
  >
    <path d="M7.42462 1.57538L2 7L7.42462 12.4246" strokeWidth="2" />
  </svg>
);
