import React, { HTMLProps, PropsWithChildren } from 'react';
import {
  ButtonIconPosition,
  ButtonIntent,
  ButtonMode,
  ButtonTier,
  IButtonProps,
} from './button.types';
import classnames from 'classnames';
import styles from './button.module.css';
import { ChevronLeft } from '../svgs/chevron-left';
import { getSVGIcon } from '../svgs';
import { Spinner } from '../spinner/spinner';

export const Button = ({
  children,
  tier = ButtonTier.Primary,
  className,
  type,
  iconLeft,
  iconRight,
  working,
  workingText,
  ...props
}: PropsWithChildren<IButtonProps & HTMLProps<HTMLButtonElement>>) => (
  <button
    type={(type as 'button' | 'submit' | 'reset' | undefined) ?? 'button'}
    {...props}
    className={classnames(className, styles.button, {
      [styles['button--primary']]: tier === ButtonTier.Primary,
      [styles['button--secondary']]: tier === ButtonTier.Secondary,
      [styles['button--tertiary']]: tier === ButtonTier.Tertiary,
      [styles['button--quaternary']]: tier === ButtonTier.Quaternary,
    })}
  >
    {iconLeft && getSVGIcon(iconLeft)}
    {working ? (
      <>
        <Spinner size="small" color="inverted" />
        {workingText ? <>&nbsp;{workingText}</> : ''}
      </>
    ) : (
      children
    )}
    {iconRight && getSVGIcon(iconRight)}
  </button>
);
