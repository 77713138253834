import { useNavigate } from 'react-router-dom';
import { useCreateOrderMutation } from '../app/api/api';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { Button } from '../button/button';
import { ButtonMode, ButtonTier } from '../button/button.types';
import { CheckoutTable } from '../checkout/checkout-table';
import { close } from '../modal/modalSlice';
import styles from './cart-modal.module.css';
import { selectCartITems } from './cart.slice';

export const CartModal = () => {
  const navigate = useNavigate();
  const cart = useAppSelector(selectCartITems);
  const dispatch = useAppDispatch();
  return (
    <>
      <CheckoutTable mode={ButtonMode.Dark} />
      <div className={styles.buttons}>
        <Button
          tier={ButtonTier.Secondary}
          onClick={() => {
            dispatch(close());
          }}
        >
          Continue browsing
        </Button>
        {cart.length > 0 && (
          <Button
            onClick={async () => {
              navigate('/checkout');
              dispatch(close());
            }}
          >
            Checkout now
          </Button>
        )}
      </div>
    </>
  );
};
