import { GalleryItem } from '../gallery-item/gallery-item';
import { GalleryPageProps } from './gallery-page.type';
import styles from './gallery-page.module.css';

export const GalleryPage = ({
  items,
  onItemClicked,
  linkPrefix,
  className,
}: GalleryPageProps) => {
  return (
    <div className={`${styles.galleryPage} ${className}`}>
      {items.map((item, index) => (
        <GalleryItem
          linkPrefix={linkPrefix}
          onItemClicked={(item) => {
            onItemClicked?.(item, index);
          }}
          key={item.id}
          item={item}
        />
      ))}
    </div>
  );
};
