export const DecreaseArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="22"
    viewBox="0 0 13 22"
    fill="none"
  >
    <path
      d="M0.707106 11.9246C0.316582 11.5341 0.316582 10.9009 0.707107 10.5104L10.4016 0.815885C11.0316 0.18592 12.1087 0.632088 12.1087 1.52299L12.1087 20.912C12.1087 21.8029 11.0316 22.2491 10.4016 21.6191L0.707106 11.9246Z"
      fill="#83BD5E"
    />
  </svg>
);
