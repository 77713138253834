import { SliderItemProps } from './slider-item.types';
import styles from './slider-item.module.css';
import { GalleryItemPlaceholder } from '../gallery-item/gallery-item-placeholder';
import {
  ButtonIconPosition,
  ButtonIntent,
  ButtonMode,
  ButtonTier,
} from '../button/button.types';
import { Button } from '../button/button';
import { HeartIcon } from '@heroicons/react/24/outline';
import { HeartIcon as HeartIconFilled } from '@heroicons/react/24/solid';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  addToFavourites,
  removeFromFavourites,
  selectFavourites,
} from './favourites.slice';

import { Icon } from '../svgs';

export const SliderItem = ({
  item,
  widthOverride,
  isFavourite,
  onFavouriteToggle,
  ...props
}: SliderItemProps) => {
  const dispatch = useAppDispatch();
  return (
    <div className={styles.container}>
      <div
        {...props}
        className={styles.item}
        style={{
          width: widthOverride ?? '100vw',
        }}
      >
        <Button
          className={`${styles.favouriteButton} ${
            isFavourite ? styles.favourite : ''
          }`}
          onClick={() => {
            onFavouriteToggle?.(item);
          }}
          tier={ButtonTier.Tertiary}
          iconRight={isFavourite ? Icon.heartIconFilled : Icon.heartIcon}
        >
          Favourite
        </Button>
        {item.imageUrl && item.imageUrl !== '' ? (
          <img
            onClick={(evt) => {
              evt.currentTarget.requestFullscreen();
              //evt.target.requestFullscreen();
            }}
            draggable="false"
            className={styles.image}
            src={item.imageUrl}
            alt={item.id}
          />
        ) : (
          <GalleryItemPlaceholder />
        )}
        <div className={styles.title}>{item.title}</div>
      </div>
    </div>
  );
};
