import React, { HTMLProps, PropsWithChildren } from 'react';
import {
  ButtonIconPosition,
  ButtonIntent,
  ButtonMode,
  ButtonTier,
  ILinkButtonProps,
} from './button.types';
import classnames from 'classnames';
import styles from './button.module.css';
import { ChevronLeft } from '../svgs/chevron-left';
import { NavLink } from 'react-router-dom';
import { getSVGIcon } from '../svgs';

export const LinkButton = ({
  children,
  tier = ButtonTier.Primary,
  className,
  type,
  iconLeft,
  iconRight,
  to,
  ...props
}: PropsWithChildren<ILinkButtonProps & HTMLProps<HTMLAnchorElement>>) => (
  <NavLink
    to={to}
    className={classnames(className, styles.button, styles.linkButton, {
      [styles['button--primary']]: tier === ButtonTier.Primary,
      [styles['button--secondary']]: tier === ButtonTier.Secondary,
      [styles['button--tertiary']]: tier === ButtonTier.Tertiary,
    })}
  >
    {iconLeft && getSVGIcon(iconLeft)}
    {children}
    {iconRight && getSVGIcon(iconRight)}
  </NavLink>
);
