import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface NavState {
  isOpen: boolean;
}

const initialState: NavState = {
  isOpen: false,
};

export const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    open: (state) => {
      state.isOpen = true;
    },
    close: (state) => {
      state.isOpen = false;
    },
  },
});

export const selectNav = (state: RootState) => state.nav;

export const { open, close } = navSlice.actions;

export default navSlice.reducer;
