import { ChevronLeft } from './chevron-left';
import { Heart } from './heart';
import { HeartFilled } from './heart-filled';
import { IncreaseArrow } from './increase-arrow';
import { DecreaseArrow } from './decrease-arrow';
import { X } from './x';

export enum Icon {
  chevronLeft = 'chevronLeft',
  heartIcon = 'heartIcon',
  heartIconFilled = 'heartIconFilled',
  increaseArrow = 'increaseArrow',
  decreaseArrow = 'decreaseArrow',
  x = 'x',
}

export const getSVGIcon = (icon: Icon) => {
  switch (icon) {
    case Icon.chevronLeft:
      return ChevronLeft();
    case Icon.heartIcon:
      return Heart();
    case Icon.heartIconFilled:
      return <HeartFilled />;
    case Icon.increaseArrow:
      return <IncreaseArrow />;
    case Icon.decreaseArrow:
      return <DecreaseArrow />;
    case Icon.x:
      return <X />;
  }
};
