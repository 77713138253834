import { GalleryPage as GalleryData, IProduct } from '@topshots/core-models';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {} from '../../app/api/api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  Breadcrumbs,
  galleryCrumbToDisplayCrumb,
} from '../../breadcrumbs/breadcrumbs';
import { GalleryPage } from '../../gallery-page/gallery-page';
import { Slider } from '../../slider/slider';
import styles from './favourites-page.module.css';
import { ProductList } from '../album-page/product-list';
import { ModalContent, open } from '../../modal/modalSlice';
import {
  addToFavourites,
  removeFromFavourites,
  selectFavourites,
} from '../../slider/favourites.slice';
import { EventProductList } from '../../event-product-list/event-product-list';
import { add, selectMontage } from '../../cart/montage.slice';
import { PageTitle } from '../page-title/page-title';

export const FavouritesPage = ({
  layout = 'grid',
}: {
  layout?: 'grid' | 'slider';
}) => {
  const favourites = useAppSelector(selectFavourites);
  const { index } = useParams<{ index: string }>();
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(
    +(index ?? 0)
  );
  const navigate = useNavigate();
  const montage = useAppSelector(selectMontage);
  const dispatch = useAppDispatch();
  const handleAddToCart = (product: IProduct) => {
    dispatch(
      open({
        title: product.imagesRequired > 1 ? product.name : 'Add to cart',
        contentKey:
          product.imagesRequired > 1
            ? ModalContent.StartMontage
            : ModalContent.AddToCart,
        contentPayload: {
          product: product,
          images: [favourites[currentImageIndex].item],
          albumId: favourites[currentImageIndex].albumId,
          eventId: favourites[currentImageIndex].eventId,
        },
      })
    );
  };
  const handleAddMontageToCart = (product: IProduct) => {
    dispatch(
      open({
        title: 'Add to cart',
        contentKey: ModalContent.AddToCartMultiple,
        contentPayload: {
          product: product,
          images: montage.items,
          albumId: favourites[currentImageIndex].albumId,
          eventId: favourites[currentImageIndex].eventId,
        },
      })
    );
  };
  const crumbs = [
    { display: 'Gallery', link: '/' },
    { display: 'Favourites', link: '/favourites' },
  ];
  if (favourites.length === 0) {
    return (
      <div className={styles.page}>
        <PageTitle
          title="Favourites"
          backLink={{
            to: crumbs[crumbs.length - 2].link,
            label: crumbs[crumbs.length - 2].display,
          }}
          crumbs={crumbs}
        />
        {/* <Breadcrumbs
          className={styles.crumbs}
          crumbs={[
            { display: 'Gallery', link: '/' },
            { display: 'Favourites', link: '/favourites' },
          ]}
        />
        <h1 className={styles.title}>Favourites</h1> */}
        <p>You don't have any favourites yet!</p>
      </div>
    );
  }
  return (
    <div
      className={`${styles.page} ${
        layout === 'grid' ? styles.pageGrid : styles.pageSlider
      }`}
    >
      <PageTitle
        title="Favourites"
        backLink={{
          to: crumbs[crumbs.length - 2].link,
          label: crumbs[crumbs.length - 2].display,
        }}
        crumbs={crumbs}
      />
      {/* <Breadcrumbs
        className={styles.crumbs}
        crumbs={[
          { display: 'Gallery', link: '/' },
          { display: 'Favourites', link: '/favourites' },
        ]}
      />
      <h1 className={styles.title}>Favourites</h1> */}

      {
        // eslint-disable-next-line no-constant-condition
        layout === 'grid' ? (
          <GalleryPage
            className={styles.gallery}
            linkPrefix={{
              album: `/event/`,
            }}
            onItemClicked={(item, index) => {
              if (item.type === 'image') {
                navigate(`slide/${index}`);
              }
            }}
            items={favourites.map((f) => f.item)}
            // {...data}
          />
        ) : (
          <>
            <Slider
              onFavouriteToggle={(item, index) => {
                const albumId = favourites[index].albumId;
                const eventId = favourites[index].eventId;
                if (
                  favourites.find(
                    (f) =>
                      f.albumId === albumId &&
                      f.eventId === eventId &&
                      f.item.id === item.id
                  )
                ) {
                  dispatch(
                    removeFromFavourites({
                      eventId: eventId ?? '',
                      albumId: albumId ?? '',
                      item,
                    })
                  );
                } else {
                  dispatch(
                    addToFavourites({
                      eventId: eventId ?? '',
                      albumId: albumId ?? '',
                      item,
                    })
                  );
                }
              }}
              onIndexChanged={(index) => {
                setCurrentImageIndex(index);
              }}
              className={styles.slider}
              defaultIndex={+(index ?? 0)}
              items={favourites.map((f) => f.item)}
              // {...(data as GalleryData)}
            />
            <EventProductList
              onAddToCart={handleAddToCart}
              onAddMontageToCart={handleAddMontageToCart}
              onAddImageToMontage={() => {
                dispatch(add(favourites[currentImageIndex].item));
              }}
              eventId={favourites[currentImageIndex].eventId}
              className={styles.products}
            />
            {/* <div className={styles.products}>
               {digitalProducts && digitalProducts.length > 0 && (
                <>
                  <h3 className={styles.productGroupTitle}>
                    Digital downloads
                  </h3>
                  <ProductList
                    products={digitalProducts}
                    onAddToCart={handleAddToCart}
                  />
                </>
              )}
              {physicalProducts && physicalProducts.length > 0 && (
                <>
                  <h3 className={styles.productGroupTitle}>Mounted prints</h3>
                  <ProductList
                    products={physicalProducts}
                    onAddToCart={handleAddToCart}
                  />
                </>
              )} 
            </div> */}
          </>
        )
      }
    </div>
  );
};
