import { createElement, useEffect } from 'react';

export const CookiePolicy = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return createElement('div', {
    name: 'termly-embed',
    'data-id': 'b74ef2ae-7411-41b7-9684-a50277bd8e70',
  });
};
