import { Breadcrumbs } from '../../breadcrumbs/breadcrumbs';
import { Checkout } from '../../checkout/checkout';
import styles from './checkout-page.module.css';

export const CheckoutPage = () => {
  return (
    <div className={styles.yearsPage}>
      <Breadcrumbs
        crumbs={[
          { display: 'Gallery', link: '/' },
          { display: 'Checkout', link: '/checkout' },
        ]}
      />
      <h1>Checkout</h1>
      <Checkout />
    </div>
  );
};
