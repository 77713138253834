import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import styles from './nav.module.css';
import { selectCartITems } from '../../cart/cart.slice';
import { selectFavourites } from '../../slider/favourites.slice';
import { ModalContent, open } from '../../modal/modalSlice';
import classNames from 'classnames';
import { selectNav, open as navOpen, close } from './navSlice';
import {
  Bars3Icon,
  ShoppingBagIcon,
  HeartIcon,
} from '@heroicons/react/24/solid';
import { selectIsAuthenticated } from '../../login/authSlice';

export const Nav = () => {
  const navData = useAppSelector(selectNav);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const cart = useAppSelector(selectCartITems);
  const favourties = useAppSelector(selectFavourites);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(close());
  };
  return (
    <>
      <div
        className={classNames(styles.mainNav__bg, {
          [styles.mainNav__bg__open]: navData.isOpen,
        })}
        onClick={() => {
          dispatch(close());
        }}
      ></div>
      {favourties.length > 0 && (
        <div
          className={styles.topBar__menu}
          onClick={() => {
            navigate('/favourites');
          }}
        >
          <HeartIcon color={'white'} />
        </div>
      )}
      {cart.length > 0 && (
        <div
          className={styles.topBar__menu}
          onClick={() => {
            dispatch(
              open({
                title: 'Your cart',
                contentKey: ModalContent.Cart,
              })
            );
          }}
        >
          <ShoppingBagIcon color={'white'} />
        </div>
      )}

      <div
        className={styles.topBar__menu}
        onClick={() => {
          dispatch(navOpen());
        }}
      >
        <Bars3Icon color={'white'} />
      </div>
      <nav
        className={classNames(styles.nav, {
          [styles.open]: navData.isOpen,
        })}
      >
        <Link onClick={onClick} className={styles.link} to="/">
          Events Gallery
        </Link>
        <a
          className={styles.link}
          href="https://www.topshots.org.uk/portfolio/"
        >
          Portfolio
        </a>
        <a className={styles.link} href="https://www.topshots.org.uk/about/">
          About Us
        </a>
        <a
          className={styles.link}
          href="https://www.topshots.org.uk/copyright/"
        >
          Copyright
        </a>
        <a className={styles.link} href="https://www.topshots.org.uk/contact/">
          Contact
        </a>
        {favourties.length > 0 && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            className={styles.link}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              dispatch(close());
              navigate('/favourites');
            }}
          >
            Favourites ({favourties.length})
          </a>
        )}
        {cart.length > 0 && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            className={styles.link}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              dispatch(close());
              dispatch(
                open({
                  title: 'Your cart',
                  contentKey: ModalContent.Cart,
                })
              );
            }}
          >
            Cart ({cart.length})
          </a>
        )}
        {isAuthenticated ? (
          // <a className={styles.link} href="/account/">
          //   Your account
          // </a>
          <Link className={styles.link} to="/account/">
            Your account
          </Link>
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            className={styles.link}
            onClick={() => {
              dispatch(
                open({
                  title: 'Log in',
                  contentKey: ModalContent.Login,
                  contentPayload: {
                    redirect: '/account/',
                  },
                })
              );
            }}
          >
            Login
          </a>
        )}
      </nav>
    </>
  );
};
