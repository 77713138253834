import { useGetCheckoutTotalQuery, useGetImagesQuery } from '../app/api/api';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { Button } from '../button/button';
import { ButtonIntent, ButtonMode, ButtonTier } from '../button/button.types';
import {
  removeFromCart,
  selectCartITems,
  selectProductQuantity,
  updateCartQuantity,
} from '../cart/cart.slice';
import { CartItem } from '../cart/cart.types';
import { Currency } from '../currency/currency';
import styles from './checkout-table.module.css';
import { IProduct } from '@topshots/core-models';
import {
  useGetProductAddOnsQuery,
  useGetProductQuery,
} from '../app/api/products';
import { useGetEventQuery } from '../app/api/events';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Quant } from '../quant/quant';
import { Spinner } from '../spinner/spinner';

export const CheckoutTable = ({
  mode,
  coupon,
}: {
  mode: ButtonMode;
  coupon?: string;
}) => {
  const cartItems = useAppSelector(selectCartITems);
  const { data: cartTotal, isLoading: totalIsLoadin } =
    useGetCheckoutTotalQuery({
      cart: cartItems,
      coupon: coupon,
    });
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th className={styles.remove}>&nbsp;</th>
          <th>Product</th>
          <th className={styles.priceTitle}>Price</th>
          <th className={styles.quantTitle}>Quantity</th>
          <th className={styles.subTitle}>Subtotal</th>
        </tr>
      </thead>
      <tbody>
        {cartItems.map((item, index) => (
          <CartRow mode={mode} key={index} index={index} item={item} />
        ))}
        <tr>
          <th colSpan={4} className={styles.totalTitle}>
            Shipping
          </th>
          <td className={styles.subTitle}>
            <Currency value={cartTotal?.shipping ?? 0} />
          </td>
        </tr>
        {(cartTotal?.discount ?? 0) > 0 && (
          <tr>
            <th colSpan={4} className={styles.totalTitle}>
              Discount: {coupon}
            </th>
            <td className={styles.subTitle}>
              -<Currency value={cartTotal?.discount ?? 0} />
            </td>
          </tr>
        )}
        <tr>
          <th colSpan={4} className={styles.totalTitle}>
            Total
          </th>
          <td className={styles.subTitle}>
            <Currency value={cartTotal?.total ?? 0} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export const CartRow = ({
  item,
  index,
  mode,
}: {
  item: CartItem;
  index: number;
  mode: ButtonMode;
}) => {
  const dispatch = useAppDispatch();
  const { data: product, isLoading: isLoadingProduct } = useGetProductQuery(
    item.productId
  );

  const { data: addOns, isLoading: isLoadingProductAddons } =
    useGetProductAddOnsQuery(item.productId);

  const { data: imagesData, isLoading: isLoadingImage } = useGetImagesQuery({
    ids: item.imageIds,
  });
  const { data: event, isLoading: isLoadingEvent } = useGetEventQuery(
    item.eventId
  );

  // const cartItems = useAppSelector(selectCartITems);

  // const calculateUnitPrice = (product: IProduct) => {
  //   if (product.pricingModel === 'standard')
  //     return {
  //       discounted: false,
  //       from: product.price,
  //       to: product.price,
  //     };
  //   const quant = cartItems
  //     .filter((ci) => ci.productId === product._id.toString())
  //     .reduce((acc, ci) => acc + ci.quantity, 0);
  //   const tier = product.priceTiers.find(
  //     (tp) => tp.minVolume >= quant && (!tp.maxVolume || tp.maxVolume <= quant)
  //   );

  //   return {
  //     discounted: tier?.price !== product.price,
  //     from: product.price,
  //     to: tier?.price ?? product.price,
  //   };
  // };
  const cartQuant = useAppSelector(selectProductQuantity(item.productId));
  let unitPrice = {
    discounted: false,
    from: product?.price ?? 100,
    to: product?.price ?? 100,
  };

  const eventPrice = event?.pricingOverrides.find(
    (p) => p.product_id.toString() === product?._id.toString()
  );
  if (eventPrice) {
    unitPrice.from = eventPrice.price;
    unitPrice.to = eventPrice.price;
  }
  switch (product?.pricingModel) {
    case 'tiered':
      // eslint-disable-next-line no-case-declarations
      const tier = product.priceTiers.find(
        (tp) =>
          tp.minVolume <= cartQuant + 1 &&
          (tp.maxVolume == null || tp.maxVolume >= cartQuant)
      );
      unitPrice = {
        discounted: tier?.price !== product.priceTiers[0].price,
        from: product.priceTiers[0].price,
        to: tier?.price ?? product.price,
      };
      break;
  }

  if (isLoadingProduct || isLoadingImage || !product)
    return (
      <tr>
        <td>
          <Spinner />
        </td>
      </tr>
    );

  return (
    <>
      <tr>
        <td
          className={`${styles.remove} ${
            item.addOnIds.length > 0 ? styles.noBorder : ''
          }`}
        >
          <TrashIcon
            onClick={() => {
              dispatch(
                removeFromCart({
                  index,
                })
              );
            }}
            className={styles.removeIcon}
            height={24}
            width={24}
          />
        </td>
        <td>
          <div className={styles.info}>
            <div
              className={
                (imagesData?.length ?? 1) > 1 ? styles.images : styles.image
              }
            >
              {imagesData?.map((image) => (
                <img
                  key={image.id}
                  className={
                    imagesData?.length > 1 ? styles.gridImage : styles.image
                  }
                  alt={image?.name}
                  src={image?.url}
                />
              ))}
            </div>
            <p className={styles.productName}>{product?.name}</p>
            <ul className={styles.imageName}>
              {imagesData?.map((image) => (
                <li key={image.id}>
                  {image.name.includes('.')
                    ? image.name.split('.')[0]
                    : image.name}
                </li>
              ))}
            </ul>
          </div>
        </td>
        <td className={styles.priceCell}>
          <Currency
            value={unitPrice.from ?? 0}
            className={unitPrice.discounted ? styles.currencyDiscounted : ''}
          />
          {unitPrice.discounted && <Currency value={unitPrice.to} />}
        </td>
        <td className={styles.quantCell}>
          <Quant
            includeLabel={false}
            initialQuantity={item.quantity}
            onChange={(newQuantity) => {
              dispatch(updateCartQuantity({ index, quantity: newQuantity }));
            }}
          />
        </td>
        <td className={styles.subCell}>
          <Currency
            value={(unitPrice?.from ?? 0) * item.quantity}
            className={unitPrice.discounted ? styles.currencyDiscounted : ''}
          />
          {unitPrice.discounted && (
            <Currency value={unitPrice.to * item.quantity} />
          )}
        </td>
      </tr>
      {item.addOnIds.map((addOnId, index) => {
        const addOn = (addOns ?? []).find((a) => a._id.toString() === addOnId);
        return (
          <tr key={index}>
            <td className={styles.remove}>&nbsp;</td>
            <td className={styles.productName}>+ {addOn?.name}</td>
            <td className={styles.priceCell}>
              <Currency value={addOn?.price ?? 0} />
            </td>
            <td className={styles.quantCell}>x1</td>
            <td className={styles.subCell}>
              <Currency value={addOn?.price ?? 0} />
            </td>
          </tr>
        );
      })}
    </>
  );
};
