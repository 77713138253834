import { Elements } from '@stripe/react-stripe-js';
import { CheckoutCompleteDetails } from './checkout-complete-details';

export const CheckoutComplete = () => {
  return (
    <Elements
      options={{
        appearance: {
          theme: 'night',
          variables: {
            colorBackground: '#000000',
          },
        },
      }}
      stripe={(window as any).stripePromise}
    >
      <CheckoutCompleteDetails />
    </Elements>
  );
};
