import { GalleryItem } from '@topshots/core-models';
import styles from './slider2.module.css';
import { useEffect, useRef, useState } from 'react';
import { ChevronLeft } from '../svgs/chevron-left';
import { Button } from '../button/button';
import { ButtonTier } from '../button/button.types';

export const Slider2 = ({
  slides,
  defaultIndex = 0,
  onIndexChange,
}: {
  slides: GalleryItem[];
  defaultIndex?: number;
  onIndexChange?: (index: number) => void;
}) => {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [referenceWidth, setReferenceWidth] = useState<number>(
    window.innerWidth
  );
  const [currentIndex, setCurrentIndex] = useState<number>(
    defaultIndex - 1 > slides.length ? slides.length - 1 : defaultIndex
  );
  const moveToIndex = (i: number, refWidth: number) => {
    if (i < 0) i = 0;
    if (i > slides.length - 1) i = slides.length - 1;
    const offset = refWidth * i;
    scrollerRef.current?.scrollTo(Math.abs(offset), 0);
  };
  useEffect(() => {
    if (scrollerRef.current && containerRef.current) {
      const refWidth = containerRef.current?.getBoundingClientRect().width;
      setReferenceWidth(refWidth ?? window.innerWidth);
      moveToIndex(currentIndex, refWidth);
      const observer = new IntersectionObserver(
        (observations) => {
          observations.forEach((observation) => {
            observation.target.classList.toggle(
              styles.slideVisible,
              observation.isIntersecting
            );
            if (observation.isIntersecting) {
              const selectedIndex = Number(
                (observation.target as HTMLElement).dataset.index
              );
              setCurrentIndex(selectedIndex);
              onIndexChange?.(selectedIndex);
            }
          });
        },
        {
          root: scrollerRef.current,
          threshold: 0.6,
        }
      );

      for (const el of document.querySelectorAll(`.${styles.slide}`)) {
        observer.observe(el);
      }
      return () => {
        if (scrollerRef.current) {
          observer.unobserve(scrollerRef.current);
        }
      };
    }
  }, [scrollerRef, containerRef]);
  return (
    <div className={styles.slider} ref={containerRef}>
      <div className={styles.controlsLeft}>
        <Button
          tier={ButtonTier.Tertiary}
          onClick={() => {
            moveToIndex(currentIndex - 1, referenceWidth);
          }}
        >
          <ChevronLeft />
        </Button>
      </div>
      <div className={styles.scroller} ref={scrollerRef}>
        {slides.map((slide, i) => (
          <div key={slide.id} data-index={i} className={styles.slide}>
            <figure className={styles.slideFigure}>
              <img
                onClick={(evt) => {
                  (evt?.currentTarget as any)?.webkitRequestFullscreen?.();
                }}
                width="800"
                height="600"
                className={styles.slideFigureImage}
                loading="lazy"
                src={slide.imageUrl}
                alt={slide.title}
              />
              <figcaption className={styles.caption}>{slide.title}</figcaption>
            </figure>
          </div>
        ))}
      </div>
      <div className={styles.controlsRight}>
        <Button
          tier={ButtonTier.Tertiary}
          onClick={() => {
            moveToIndex(currentIndex + 1, referenceWidth);
          }}
        >
          <ChevronLeft />
        </Button>
      </div>
    </div>
  );
};
