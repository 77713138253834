import { HTMLAttributes } from 'react';
import { useGetSettingsQuery } from '../app/api/api';
import { Spinner } from '../spinner/spinner';

export const Currency = ({
  value,
  className,
}: {
  value: number;
  className?: string;
}) => {
  const { data, isLoading } = useGetSettingsQuery();
  if (isLoading) {
    return <Spinner />;
  }
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: data?.currency,
  });
  return <span className={className}>{formatter.format(value)}</span>;
};
