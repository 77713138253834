import { loadStripe } from '@stripe/stripe-js';

export const initStripe = async () => {
  const response = await fetch(
    `${process.env.NX_TOPSHOTS_BASE_URL ?? ''}/api/gallery/settings`
  );
  const data = await response.json();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).stripePromise = loadStripe(data.stripePublishableKey);
};
