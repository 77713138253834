import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GalleryItem, IImage, IProduct } from '@topshots/core-models';
import { RootState } from '../app/store';
import { CartItem } from './cart.types';

// export interface MontageItem {

//   // imageId: string;
//   // imageUrl: string;
//   image: GalleryItem;
// }

export interface MontageState {
  items: GalleryItem[];
  albumId: string;
  eventId: string;
  requiredItems: number;
  // productId: string;
  // productName: string;
  product?: IProduct;
  enabled: boolean;
}

const initialState: MontageState = {
  items: [],
  requiredItems: 0,
  albumId: '',
  eventId: '',
  //productId: '',
  // productName: '',
  product: undefined,
  enabled: false,
};

export const montageSlice = createSlice({
  name: 'montage',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    start: (
      state,
      action: PayloadAction<{
        product: IProduct;
        item: GalleryItem;
        albumId: string;
        eventId: string;
      }>
    ) => {
      state.items = [action.payload.item];
      state.albumId = action.payload.albumId;
      state.eventId = action.payload.eventId;
      state.requiredItems = action.payload.product.imagesRequired;
      // state.productId = action.payload.product._id.toString();
      // state.productName = action.payload.product.name;
      state.product = action.payload.product;
      state.enabled = true;
    },
    add: (state, action: PayloadAction<GalleryItem>) => {
      state.items.push(action.payload);
    },
    remove: (state, action: PayloadAction<number>) => {
      state.items.splice(action.payload, 1);
    },
    cancel: (state) => {
      state.items = [];
      state.requiredItems = 0;
      state.albumId = '';
      state.eventId = '';
      // state.productId = '';
      state.product = undefined;
      // state.productName = '';
      state.enabled = false;
    },
  },
});

export const { start, add, cancel, remove } = montageSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectMontage = (state: RootState) => state.montage;

export default montageSlice.reducer;
