import React, { useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import styles from './accordian.module.css';

interface AccordionProps {
  children: React.ReactNode;
  isOpen?: boolean;
  id: string;
  onToggle?: (isOpen: boolean) => void;
  onTitleClick?: (id: string) => void;
  title: string;
}

const Accordion: React.FC<AccordionProps> = ({
  children,
  isOpen,
  onToggle,
  id,
  title,
  onTitleClick,
}) => {
  const [isOpenInternal, setIsOpenInternal] = useState(false);
  const contentProps = useSpring({
    height: isOpen || (isOpen === undefined && isOpenInternal) ? 'auto' : 0,
    opacity: isOpen || (isOpen === undefined && isOpenInternal) ? 1 : 0,
    overflow: 'hidden',
  });

  return (
    <div className={styles.accordian} id={id}>
      <div
        className={styles.title}
        onClick={() => {
          if (isOpen === undefined) {
            const o = !isOpenInternal;
            setIsOpenInternal(o);
            onToggle?.(o);
          } else {
            onTitleClick?.(id);
          }
        }}
      >
        {title}
      </div>
      <animated.div style={contentProps}>
        {/* {(isOpen || (isOpen === undefined && isOpenInternal)) && children} */}
        <div className={styles.container}>{children}</div>
      </animated.div>
    </div>
  );
};

export default Accordion;
