import { SliderItem } from './slider-item';
import { SliderProps } from './slider.types';
import styles from './slider.module.css';
import { useSpring, animated } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import React, { useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Button } from '../button/button';
import {
  ButtonIconPosition,
  ButtonIntent,
  ButtonTier,
} from '../button/button.types';
import { useAppSelector } from '../app/hooks';
import { selectFavourites } from './favourites.slice';

export const Slider = ({
  items,
  defaultIndex = 0,
  className,
  onIndexChanged,
  onFavouriteToggle,
}: SliderProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const favourites = useAppSelector(selectFavourites);
  const [referenceWidth, setReferenceWidth] = useState<number>(
    window.innerWidth
  );
  useEffect(() => {
    setReferenceWidth(
      containerRef.current?.getBoundingClientRect().width ?? window.innerWidth
    );
    moveToIndex(currentIndex, true);
  }, [containerRef.current?.getBoundingClientRect().width]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setReferenceWidth(containerRef.current.getBoundingClientRect().width);
        const offset = 0 - referenceWidth * currentIndex;
        api.start({ x: offset, immediate: true });
        setCurrentOffset(offset);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  const [currentOffset, setCurrentOffset] = useState<number>(
    0 - referenceWidth * defaultIndex
  );
  const [currentIndex, setCurrentIndex] = useState<number>(defaultIndex);
  const [{ x }, api] = useSpring(() => ({
    x: 0 - referenceWidth * defaultIndex,
  }));
  const moveToIndex = (i: number, immediate = false) => {
    if (i < 0) i = 0;
    if (i > items.length - 1) i = items.length - 1;
    const offset = 0 - referenceWidth * i;
    api.start({ x: offset, immediate });
    setCurrentOffset(offset);
    setCurrentIndex(i);
    onIndexChanged?.(i);
  };
  const bind = useDrag(
    ({ pinching, down, cancel, movement: [mx] }) => {
      if (pinching) return cancel();
      const contWidth = containerRef.current?.getBoundingClientRect()
        .width as number;
      let newIndex = currentIndex;
      if (mx < 0 - contWidth / 2) {
        // moved left, move to next slide
        newIndex = currentIndex + 1;
      } else if (mx > contWidth / 2) {
        // moved right, move to previous slide
        newIndex = Math.max(currentIndex - 1, 0);
      }

      if (!down) {
        moveToIndex(newIndex);
      } else {
        api.start({ x: currentOffset + mx, immediate: down });
      }
    },
    { axis: 'x' }
  );

  return (
    <div ref={containerRef} className={`${styles.container} ${className}`}>
      <animated.div {...bind()} style={{ x }} className={styles.slider}>
        {items.map((item, index) => {
          const isFavourite =
            favourites.find((f) => f.item.id === item.id) != null;
          return (
            <SliderItem
              onFavouriteToggle={() => {
                onFavouriteToggle?.(item, index);
              }}
              isFavourite={isFavourite}
              widthOverride={referenceWidth}
              key={item.id}
              item={item}
            />
          );
        })}
      </animated.div>
      {currentIndex > 0 && (
        <Button
          className={styles.previous}
          tier={ButtonTier.Tertiary}
          onClick={() => {
            moveToIndex(currentIndex - 1);
          }}
        >
          <ChevronLeftIcon /> Previous
        </Button>
      )}
      {currentIndex < items.length - 1 && (
        <Button
          className={styles.next}
          tier={ButtonTier.Tertiary}
          onClick={() => {
            moveToIndex(currentIndex + 1);
          }}
        >
          Next <ChevronRightIcon />
        </Button>
      )}
    </div>
  );
};
