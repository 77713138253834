import react from 'react';
import { Icon } from '../svgs';

export interface IButtonProps {
  tier?: ButtonTier;
  iconLeft?: Icon;
  iconRight?: Icon;
  working?: boolean;
  workingText?: string;
}

export interface ILinkButtonProps extends IButtonProps {
  to: string;
}

export enum ButtonTier {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Quaternary = 'quaternary',
}

export enum ButtonIntent {
  Positive = 'positive',
  Negative = 'negative',
  Neutral = 'neutral',
}

export enum ButtonMode {
  Light = 'light',
  Dark = 'dark',
}

export enum ButtonIconPosition {
  Left = 'left',
  Right = 'right',
}
