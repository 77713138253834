import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { CartItem } from './cart.types';

export interface CartState {
  items: CartItem[];
}

const initialState: CartState = {
  items: [],
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addToCart: (state, action: PayloadAction<CartItem[]>) => {
      state.items = [...state.items, ...action.payload];
    },
    clearCart: (state) => {
      state.items = [];
    },
    removeFromCart: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      state.items.splice(action.payload.index, 1);
    },
    updateCartQuantity: (
      state,
      action: PayloadAction<{
        index: number;
        quantity: number;
      }>
    ) => {
      state.items[action.payload.index].quantity = action.payload.quantity;
    },
    addCartAddOn: (
      state,
      action: PayloadAction<{
        index: number;
        addOnId: string;
      }>
    ) => {
      state.items[action.payload.index].addOnIds.push(action.payload.addOnId);
    },
    removeCartAddOn: (
      state,
      action: PayloadAction<{
        index: number;
        addOnId: string;
      }>
    ) => {
      state.items[action.payload.index].addOnIds = state.items[
        action.payload.index
      ].addOnIds.filter((id) => id !== action.payload.addOnId);
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  updateCartQuantity,
  addCartAddOn,
  removeCartAddOn,
  clearCart,
} = cartSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCartITems = (state: RootState) => state.cart.items;
export const selectProductQuantity =
  (productId: string) => (state: RootState) =>
    state.cart.items
      .filter((ci) => ci.productId === productId)
      .reduce((acc, ci) => acc + ci.quantity, 0);

export default cartSlice.reducer;
