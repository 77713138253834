import { BreadcrumbsProps, Crumb } from './breadcrumbs.types';
import { NavLink } from 'react-router-dom';
import styles from './breadcrumbs.module.css';
import { GalleryCrumb } from '@topshots/core-models';

export const galleryCrumbToDisplayCrumb = (c: GalleryCrumb): Crumb => {
  let link = '';
  switch (c.type) {
    case 'event':
      link = `/event/${c.ids.event}`;
      break;
    case 'album':
      link = `/event/${c.ids.event}/album/${c.ids.album}`;
      break;
    case 'year':
      link = `/year/${c.ids.year}`;
      break;
    case 'month':
      link = `/year/${c.ids.year}/month/${c.ids.month}`;
      break;
    default:
      console.error(`Unsupported crumb type: ${c.type}`);
  }
  return {
    display: c.title,
    link: link,
  };
};

export const Breadcrumbs = ({ crumbs, className }: BreadcrumbsProps) => {
  return (
    <nav className={`${styles.crumbs} ${className}`}>
      {crumbs.map((c, i) => (
        <div key={c.link}>
          <NavLink
            to={c.link}
            key={c.link}
            end={true}
            className={({ isActive }) =>
              `${styles.crumb} ${isActive ? styles.crumb__active : undefined}`
            }
          >
            {c.display}
          </NavLink>
          {i < crumbs.length - 1 && (
            <span key={`${c.link}_sep`} className={styles.crumb__separator}>
              /
            </span>
          )}
        </div>
      ))}
    </nav>
  );
};
