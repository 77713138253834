import { remove } from './montage.slice';
import styles from './montage-grid.module.css';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useAppDispatch } from '../app/hooks';
import { GalleryItem } from '@topshots/core-models';

export const MontageGrid = ({
  items,
  requiredItems,
  allowsDelete = false,
  className,
}: {
  items: GalleryItem[];
  requiredItems: number;
  allowsDelete?: boolean;
  className?: string;
}) => {
  const dispatch = useAppDispatch();

  return (
    <ul className={`${styles.items} ${className}`}>
      {items.map((item, i) => (
        <li key={i} className={styles.item}>
          {/* <img
            alt={item.imageId}
            className={styles.image}
            src={item.imageUrl}
          /> */}
          <div
            style={{
              backgroundImage: `url(${item.imageUrl})`,
            }}
            className={`${styles.placeholder} ${
              allowsDelete ? styles.deletable : ''
            }`}
          >
            {allowsDelete && (
              <div
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals
                  if (confirm('Are you sure you want to remove this photo?')) {
                    dispatch(remove(i));
                  }
                }}
                className={styles.deleteOverlay}
              >
                <TrashIcon className={styles.trashIcon} />
              </div>
            )}
          </div>
        </li>
      ))}
      {Array.from(Array(requiredItems - items.length)).map((_, i) => (
        <li key={i} className={styles.item}>
          <div className={styles.placeholder}>Photo {i + 1 + items.length}</div>
        </li>
      ))}
    </ul>
  );
};
