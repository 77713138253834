import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCurrentUserQuery } from '../app/api/api';
import { Button } from '../button/button';
import { ErrorText } from '../forms/error-text';
import { FormGroup } from '../forms/form-group';
import styles from './checkout-details-form.module.css';

const DetailsSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Email is required'),
  phone: Yup.string(),
});

export type CheckoutDetails = Yup.InferType<typeof DetailsSchema>;

export const CheckoutDetailForm = ({
  onSubmit,
}: {
  onSubmit: (values: Yup.InferType<typeof DetailsSchema>) => void;
}) => {
  const { data: currentUser } = useCurrentUserQuery();
  const { getFieldProps, isSubmitting, touched, errors, handleSubmit } =
    useFormik({
      initialValues: {
        firstName: currentUser?.firstName || '',
        lastName: currentUser?.lastName || '',
        email: currentUser?.email || '',
        phone: currentUser?.phone || '',
      },
      enableReinitialize: true,
      validationSchema: DetailsSchema,
      onSubmit: (values, { setSubmitting }) => {
        // handle form submission here
        setSubmitting(false);
        onSubmit(values);
      },
    });

  return (
    <form className={styles.detailsForm} onSubmit={handleSubmit}>
      <FormGroup className={styles.firstName}>
        <label htmlFor="firstName">First name</label>
        <input type="text" id="firstName" {...getFieldProps('firstName')} />
        {touched.firstName && errors.firstName ? (
          <ErrorText>{errors.firstName}</ErrorText>
        ) : null}
      </FormGroup>

      <FormGroup className={styles.lastName}>
        <label htmlFor="lastName">Last name</label>
        <input type="text" id="lastName" {...getFieldProps('lastName')} />
        {touched.lastName && errors.lastName ? (
          <ErrorText>{errors.lastName}</ErrorText>
        ) : null}
      </FormGroup>

      <FormGroup className={styles.email}>
        <label htmlFor="email">Email</label>
        <input type="email" id="email" {...getFieldProps('email')} />
        {touched.email && errors.email ? (
          <ErrorText>{errors.email}</ErrorText>
        ) : null}
      </FormGroup>

      <FormGroup className={styles.phone}>
        <label htmlFor="phone">Phone</label>
        <input type="tel" id="phone" {...getFieldProps('phone')} />
        {touched.phone && errors.phone ? (
          <ErrorText>{errors.phone}</ErrorText>
        ) : null}
      </FormGroup>

      <FormGroup>
        <Button type="submit" disabled={isSubmitting}>
          Next
        </Button>
      </FormGroup>
    </form>
  );
};
