import { useEffect } from 'react';
import { useGetCalendarPageQuery } from '../../app/api/pages';
import { GalleryPage } from '../../gallery-page/gallery-page';
import { Spinner } from '../../spinner/spinner';
import { PageTitle } from '../page-title/page-title';
import styles from './years-page.module.css';
import { useAppDispatch } from '../../app/hooks';
import { ModalContent, open } from '../../modal/modalSlice';
import { useParams } from 'react-router-dom';

export const YearsPage = ({ resetPassword }: { resetPassword?: boolean }) => {
  const { data, isLoading } = useGetCalendarPageQuery();
  const dispatch = useAppDispatch();
  const { token } = useParams<{ token: string }>();
  useEffect(() => {
    if (resetPassword) {
      dispatch(
        open({
          title: 'Set a new password',
          contentKey: ModalContent.ResetPassword,
          contentPayload: {
            token,
          },
        })
      );
    }
  }, [resetPassword]);
  return (
    <div className={styles.yearsPage}>
      <PageTitle title={data?.title} />
      {isLoading && <Spinner />}
      {!isLoading && data && <GalleryPage {...data} />}
    </div>
  );
};
