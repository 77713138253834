import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { clearToken, selectIsAuthenticated } from '../../login/authSlice';
import { useNavigate } from 'react-router-dom';
import styles from './account-page.module.css';
import { Button } from '../../button/button';
import { ButtonTier } from '../../button/button.types';
import { useGetOrderSummariesQuery } from '../../app/api/api';
import { DateTime } from '../../datetime/datetime';
import { Currency } from '../../currency/currency';

export const AccountPage = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const dispatch = useAppDispatch();
  const { data: orders, isLoading: ordersLoading } =
    useGetOrderSummariesQuery();
  const nav = useNavigate();
  if (!isAuthenticated) {
    nav('/');
    return null;
  }
  return (
    <div className={styles.page}>
      <h2 className={styles.title}>Your Account</h2>
      <div className={styles.orders}>
        <h3 className={styles.orders__title}>Your orders</h3>
        {ordersLoading ? (
          <p>Loading...</p>
        ) : (
          <table className={styles.ordersTable}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Items</th>
                <th>Status</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {orders?.length === 0 && (
                <tr>
                  <td colSpan={4}>You don't have any orders yet.</td>
                </tr>
              )}
              {orders?.map((order) => {
                return (
                  <tr
                    key={order._id.toString()}
                    onClick={() => {
                      nav(`/checkout/complete/${order._id}`);
                    }}
                  >
                    <td>
                      <DateTime d={new Date(order.created_on)} />
                    </td>
                    <td>
                      <ul>
                        {order.items.downloads.map((download, i) => (
                          <div className={styles.orderItem} key={`d_${i}`}>
                            <img
                              className={styles.orderItem__image}
                              style={{
                                maxWidth: '100px',
                              }}
                              src={download.image.url}
                              alt={`${download.productName} of ${download.image.name}`}
                            />
                            <p className={styles.orderItem__productName}>
                              {download.productName}
                            </p>
                            <p className={styles.orderItem__imageName}>
                              {download.image.name}
                            </p>
                          </div>
                        ))}
                        {order.items.prints.map((print, i) => (
                          <div className={styles.orderItem} key={`p_${i}`}>
                            {print.images.map((i) => (
                              <>
                                <img
                                  className={styles.orderItem__image}
                                  style={{
                                    maxWidth: '100px',
                                  }}
                                  src={i.url}
                                  alt={`${print.productName} of ${i.name}`}
                                />
                                <p className={styles.orderItem__productName}>
                                  {print.productName}
                                </p>
                                <p className={styles.orderItem__imageName}>
                                  {i.name}
                                </p>
                              </>
                            ))}
                          </div>
                        ))}
                      </ul>
                    </td>
                    <td>{order.status}</td>
                    <td>
                      <Currency value={order.subTotal + order.shippingCost} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {/* <pre>{JSON.stringify(orders, null, 2)}</pre> */}
      </div>
      <Button
        onClick={() => {
          dispatch(clearToken());
        }}
        className={styles.logout}
        tier={ButtonTier.Secondary}
      >
        Log out
      </Button>
    </div>
  );
};
