import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormGroup } from '../forms/form-group';
import { countries } from './countries';
import { Button } from '../button/button';
import { ErrorText } from '../forms/error-text';
import { IAddress } from '@topshots/core-models';

const AddressSchema = Yup.object({
  country: Yup.string().required('Country is required'),
  line_1: Yup.string().required('Address line 1 is required'),
  line_2: Yup.string(),
  town: Yup.string().required('City is required'),
  county: Yup.string(),
  postcode: Yup.string().required('Postal code is required'),
});

export type AddressFormValues = Yup.InferType<typeof AddressSchema>;

const AddressForm = ({
  onSubmit,
  submitText,
  address,
  working,
}: {
  submitText: string;
  onSubmit?: (values: AddressFormValues) => void;
  address?: IAddress;
  working?: boolean;
}) => {
  const formik = useFormik<AddressFormValues>({
    initialValues: {
      country: address?.country ?? 'GBR',
      line_1: address?.line_1 ?? '',
      line_2: address?.line_2 ?? '',
      town: address?.town ?? '',
      county: address?.county ?? '',
      postcode: address?.postcode ?? '',
    },
    validationSchema: AddressSchema,
    onSubmit: (values, { setSubmitting }) => {
      // handle form submission here
      setSubmitting(false);
      onSubmit?.(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormGroup>
        <label htmlFor="country">Country</label>
        <select
          name="country"
          id="country"
          value={formik.values.country}
          onChange={formik.handleChange}
        >
          {countries.map((country) => {
            return (
              <option key={country['alpha-3']} value={country['alpha-3']}>
                {country.name}
              </option>
            );
          })}
        </select>
        {formik.touched.country && formik.errors.country ? (
          <ErrorText>{formik.errors.country}</ErrorText>
        ) : null}
      </FormGroup>

      <FormGroup>
        <label htmlFor="line_1">Address Line 1</label>
        <input
          type="text"
          name="line_1"
          id="line_1"
          autoComplete="address-line1"
          value={formik.values.line_1}
          onChange={formik.handleChange}
        />
        {formik.touched.line_1 && formik.errors.line_1 ? (
          <ErrorText>{formik.errors.line_1}</ErrorText>
        ) : null}
      </FormGroup>

      <FormGroup>
        <label htmlFor="line_2">Address Line 2</label>
        <input
          type="text"
          name="line_2"
          id="line_2"
          autoComplete="address-line2"
          value={formik.values.line_2}
          onChange={formik.handleChange}
        />
      </FormGroup>

      <FormGroup>
        <label htmlFor="town">Town</label>
        <input
          type="text"
          name="town"
          id="town"
          autoComplete="address-level2"
          value={formik.values.town}
          onChange={formik.handleChange}
        />
        {formik.touched.town && formik.errors.town ? (
          <ErrorText>{formik.errors.town}</ErrorText>
        ) : null}
      </FormGroup>

      <FormGroup>
        <label htmlFor="county">County</label>
        <input
          type="text"
          name="county"
          id="county"
          autoComplete="address-level1"
          value={formik.values.county}
          onChange={formik.handleChange}
        />
      </FormGroup>

      <FormGroup>
        <label htmlFor="postcode">Postcode</label>
        <input
          type="text"
          name="postcode"
          id="postcode"
          autoComplete="postal-code"
          value={formik.values.postcode}
          onChange={formik.handleChange}
        />
        {formik.touched.postcode && formik.errors.postcode ? (
          <ErrorText>{formik.errors.postcode}</ErrorText>
        ) : null}
      </FormGroup>

      <FormGroup>
        <Button
          working={working}
          workingText={submitText}
          type="submit"
          disabled={formik.isSubmitting}
        >
          {submitText}
        </Button>
      </FormGroup>
    </form>
  );
};

export default AddressForm;
