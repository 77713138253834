import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { persistor, store } from './app/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './app/app';
import { Modal } from './modal/modal';
import { loadStripe } from '@stripe/stripe-js';
import { initStripe } from './stripe';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://eff5504b17392a24b88e11b30a544f4f@o79871.ingest.us.sentry.io/4508224776175616',
  integrations: [],
});

initStripe().then(() => {
  const tagManagerArgs = {
    gtmId: 'GTM-N2RZ465M',
  };

  TagManager.initialize(tagManagerArgs);

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
          <Modal />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
});
