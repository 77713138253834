import { useEffect, useState } from 'react';
import styles from './footer.module.css';
import { Button } from '../button/button';
import { ButtonTier } from '../button/button.types';
import { Link } from 'react-router-dom';

export const Footer = () => {
  const [tLoaded, setTLoaded] = useState(false);
  const tl = (window as any)?.['TERMLY_RESOURCE_BLOCKER_LOADED'] ?? false;
  useEffect(() => {
    if (tl) {
      setTLoaded(true);
    }
  }, [tl]);
  return (
    <footer className={styles.footer}>
      {tLoaded && (
        <Button
          tier={ButtonTier.Quaternary}
          className={`termly-display-preferences ${styles.link}`}
        >
          Consent Preferences
        </Button>
      )}
      <Link to="/privacy" className={styles.link}>
        Privacy Policy
      </Link>
      <Link to="/cookies" className={styles.link}>
        Cookie Policy
      </Link>
      <Link to="/copyright" className={styles.link}>
        Copyright
      </Link>
    </footer>
  );
};
