import { useAppDispatch, useAppSelector } from '../app/hooks';
import { AddToCartModal } from '../cart/add-to-cart-modal';
import { CartModal } from '../cart/cart-modal';
import { StartMontageModal } from '../cart/start-montage-modal';
import { Forgotten } from '../forgotten/forgotten';
import { Login } from '../login/login';
import { Register } from '../register/register';
import { ResetPassword } from '../reset-password/reset-password';
import { X } from '../svgs/x';
import { ModalContentEmpty } from './content/empty';

import styles from './modal.module.css';
import { selectModal, close, ModalContent } from './modalSlice';

export const Modal = () => {
  const modalData = useAppSelector(selectModal);
  const dispatch = useAppDispatch();
  return (
    <div
      className={`${styles.modal__bg} ${
        modalData.isOpen ? styles.modal__bg__open : ''
      }`}
      onClick={(e) => {
        if (modalData.backgroundClose && e.currentTarget === e.target) {
          dispatch(close());
        }
      }}
    >
      <div className={styles.modal}>
        <header className={styles.modal__header}>
          <div className={styles.modal__title}>{modalData.title}</div>
          <div
            className={styles.modal__close}
            onClick={() => {
              dispatch(close());
            }}
          >
            <X />
          </div>
        </header>
        <div className={styles.modal__content}>
          {modalData.contentKey === ModalContent.Empty && <ModalContentEmpty />}
          {modalData.contentKey === ModalContent.Cart && <CartModal />}
          {modalData.contentKey === ModalContent.Login && (
            <Login {...modalData.contentPayload} />
          )}
          {modalData.contentKey === ModalContent.ResetPassword && (
            <ResetPassword {...modalData.contentPayload} />
          )}
          {modalData.contentKey === ModalContent.ForgottenPassword && (
            <Forgotten {...modalData.contentPayload} />
          )}
          {modalData.contentKey === ModalContent.Register && (
            <Register {...modalData.contentPayload} />
          )}
          {modalData.contentKey === ModalContent.AddToCart && (
            <AddToCartModal {...modalData.contentPayload} />
          )}
          {modalData.contentKey === ModalContent.AddToCartMultiple && (
            <AddToCartModal {...modalData.contentPayload} />
          )}
          {modalData.contentKey === ModalContent.StartMontage && (
            <StartMontageModal {...modalData.contentPayload} />
          )}
        </div>
      </div>
    </div>
  );
};
