import React, { HTMLProps, PropsWithChildren } from 'react';
import styles from './form-group.module.css';

export interface FormGroupProps extends HTMLProps<HTMLDivElement> {
  buttons?: boolean;
}

export const FormGroup = ({
  children,
  className,
  buttons,
  ...props
}: FormGroupProps) => (
  <div
    {...props}
    className={`${className} ${styles.formGroup} ${
      buttons ? styles.buttons : ''
    }`}
  >
    {children}
  </div>
);
