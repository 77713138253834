import { HTMLProps, PropsWithChildren } from 'react';
import { ICardProps } from './card.types';
import styles from './card.module.css';
import classNames from 'classnames';

export const Card = ({
  children,
  className,
  type,
  ...props
}: PropsWithChildren<ICardProps & HTMLProps<HTMLDivElement>>) => (
  <div className={classNames(className, styles.card)}>{children}</div>
);
