import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { Button } from '../button/button';

export const CheckoutForm = ({ orderId }: { orderId: string }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [message, setMessage] = useState<string>();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setProcessing(true);
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${document.location.origin}/checkout/complete/${orderId}`,
      },
    });
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setProcessing(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement
        id="payment-element"
        options={{
          layout: {
            type: 'accordion',
          },
        }}
      />
      <Button
        //TODO: Add loading state
        style={{
          marginTop: '1rem',
        }}
        working={processing}
        workingText="Processing..."
        disabled={!stripe || !elements || processing}
        type="submit"
      >
        Pay now
      </Button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};
