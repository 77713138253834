import { useParams } from 'react-router-dom';
import {
  Breadcrumbs,
  galleryCrumbToDisplayCrumb,
} from '../../breadcrumbs/breadcrumbs';
import { GalleryPage } from '../../gallery-page/gallery-page';
import styles from './month-page.module.css';
import { useGetCalendarMonthPageQuery } from '../../app/api/pages';
import { PageTitle } from '../page-title/page-title';
import { Spinner } from '../../spinner/spinner';

export const MonthPage = () => {
  const monthName = (m: number) => {
    const d = new Date();
    d.setMonth(m - 1);
    return d.toLocaleString('default', { month: 'long' });
  };

  const { year, month } = useParams<{ year: string; month: string }>();
  const { data, isLoading } = useGetCalendarMonthPageQuery({
    year: +(year ?? '2023'),
    month: +(month ?? '1'),
  });
  const crumbs = [
    { display: 'Gallery', link: '/' },
    ...(data?.crumbs?.map(galleryCrumbToDisplayCrumb) ?? []),
    { display: data?.title ?? '', link: '' },
  ];
  return (
    <div className={styles.page}>
      <PageTitle
        title={data?.title}
        backLink={{
          to: crumbs[crumbs.length - 2].link,
          label: crumbs[crumbs.length - 2].display,
        }}
        crumbs={crumbs}
      />
      {isLoading && <Spinner />}
      {!isLoading && data && <GalleryPage {...data} />}
    </div>
  );
};
