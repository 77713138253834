import { useFormik } from 'formik';
import { ErrorText } from '../forms/error-text';
import { FormGroup } from '../forms/form-group';
import * as Yup from 'yup';
import styles from './forgotten.module.css';
import { Button } from '../button/button';
import { ButtonTier } from '../button/button.types';
import { ModalContent, close, open } from '../modal/modalSlice';
import { useAppDispatch } from '../app/hooks';
import { useForgottenLoginMutation, useLoginMutation } from '../app/api/api';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';

const ForgottenSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

export const Forgotten = () => {
  const dispatch = useAppDispatch();
  const [forgottenLogin, { isLoading }] = useForgottenLoginMutation();
  const [done, setDone] = useState<boolean>(false);
  const nav = useNavigate();
  const [error, setError] = useState<boolean>(false);
  const { getFieldProps, isSubmitting, touched, errors, handleSubmit } =
    useFormik({
      initialValues: {
        email: '',
      },
      enableReinitialize: true,
      validationSchema: ForgottenSchema,
      onSubmit: async (values, { setSubmitting }) => {
        setError(false);
        const result = await forgottenLogin(values);
        if ('error' in result) {
          console.error(result.error);
          setError(true);
          return;
        } else if ('data' in result) {
          setDone(true);
        }
        setSubmitting(false);
      },
    });

  return (
    <form onSubmit={handleSubmit}>
      {error && (
        <ErrorText>
          There was a problem with your password reset request, please try
          again.
        </ErrorText>
      )}
      <FormGroup className={styles.email}>
        <label htmlFor="email">Email</label>
        <input type="email" id="email" {...getFieldProps('email')} />
        {touched.email && errors.email ? (
          <ErrorText>{errors.email}</ErrorText>
        ) : null}
      </FormGroup>
      <FormGroup buttons={true}>
        <Button type="submit" disabled={isSubmitting}>
          Send password reset email
        </Button>
        <Button
          tier={ButtonTier.Secondary}
          type="button"
          onClick={() => {
            dispatch(close());
          }}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
      </FormGroup>
      <FormGroup>
        <Button
          tier={ButtonTier.Quaternary}
          onClick={() => {
            dispatch(
              open({
                title: 'Login',
                contentKey: ModalContent.Login,
                contentPayload: {
                  redirect: '/account/',
                },
              })
            );
          }}
        >
          Already now your password? Sign in now
        </Button>
      </FormGroup>
      {done && (
        <p>
          An email has been sent to the address provided with instructions on
          how to reset your password.
        </p>
      )}
    </form>
  );
};
