import { useFormik } from 'formik';
import { ErrorText } from '../forms/error-text';
import { FormGroup } from '../forms/form-group';
import * as Yup from 'yup';
import styles from './reset-password.module.css';
import { Button } from '../button/button';
import { ButtonTier } from '../button/button.types';
import { ModalContent, close, open } from '../modal/modalSlice';
import { useAppDispatch } from '../app/hooks';
import { setToken } from '../login/authSlice';
import {
  useLoginMutation,
  useRegisterMutation,
  useResetLoginMutation,
} from '../app/api/api';
import { useParams } from 'react-router-dom';

const ResetSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  duplicatePassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Please confirm your password'),
});

export const ResetPassword = ({ token }: { token: string }) => {
  const dispatch = useAppDispatch();
  const [reset, { isLoading }] = useResetLoginMutation();
  const { getFieldProps, isSubmitting, touched, errors, handleSubmit } =
    useFormik({
      initialValues: {
        password: '',
        duplicatePassword: '',
      },
      enableReinitialize: true,
      validationSchema: ResetSchema,
      onSubmit: async (values, { setSubmitting }) => {
        const result = await reset({ ...values, token: token ?? '' });
        if ('error' in result) {
          console.error(result.error);
        } else if ('data' in result) {
          dispatch(
            open({
              title: 'Log in',
              contentKey: ModalContent.Login,
              contentPayload: {
                redirect: '/account/',
                hasReset: true,
                resetEmail: result.data.email,
              },
            })
          );
        }
        setSubmitting(false);
      },
    });

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup className={styles.password}>
        <label htmlFor="password">Password</label>
        <input type="password" id="password" {...getFieldProps('password')} />
        {touched.password && errors.password ? (
          <ErrorText>{errors.password}</ErrorText>
        ) : null}
      </FormGroup>
      <FormGroup className={styles.password}>
        <label htmlFor="duplicatePassword">Confirm password</label>
        <input
          type="password"
          id="duplicatePassword"
          {...getFieldProps('duplicatePassword')}
        />
        {touched.duplicatePassword && errors.duplicatePassword ? (
          <ErrorText>{errors.duplicatePassword}</ErrorText>
        ) : null}
      </FormGroup>
      <FormGroup buttons={true}>
        <Button type="submit" disabled={isSubmitting}>
          Reset
        </Button>
        <Button
          tier={ButtonTier.Secondary}
          type="button"
          onClick={() => {
            dispatch(close());
          }}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
      </FormGroup>
      <FormGroup>
        <Button
          tier={ButtonTier.Quaternary}
          onClick={() => {
            dispatch(
              open({
                title: 'Log in',
                contentKey: ModalContent.Login,
                contentPayload: {
                  redirect: '/account/',
                },
              })
            );
          }}
        >
          Remembered your password? Login now
        </Button>
      </FormGroup>
    </form>
  );
};
