import { createElement, useEffect } from 'react';

export const PrivacyPolicy = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return createElement('div', {
    name: 'termly-embed',
    'data-id': '63429cca-7e97-47fb-8f4f-cadc36b46bcc',
  });
};
