import { GalleryPage as GalleryData, IProduct } from '@topshots/core-models';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  Breadcrumbs,
  galleryCrumbToDisplayCrumb,
} from '../../breadcrumbs/breadcrumbs';
import { GalleryPage } from '../../gallery-page/gallery-page';
import { Slider } from '../../slider/slider';
import styles from './album-page.module.css';
import { ModalContent, open } from '../../modal/modalSlice';
import {
  addToFavourites,
  removeFromFavourites,
  selectFavourites,
} from '../../slider/favourites.slice';
import { add, selectMontage } from '../../cart/montage.slice';
import { EventProductList } from '../../event-product-list/event-product-list';
import { useGetAlbumPageQuery } from '../../app/api/pages';
import { PageTitle } from '../page-title/page-title';

import { Slider2 } from '../../slider2/slider2';
import { Spinner } from '../../spinner/spinner';

export const AlbumPage = ({
  layout = 'grid',
}: {
  layout?: 'grid' | 'slider';
}) => {
  const favourites = useAppSelector(selectFavourites);
  const { index } = useParams<{ index: string }>();
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(
    +(index ?? 0)
  );
  const { albumId, id } = useParams<{
    albumId: string;
    id: string;
  }>();
  const navigate = useNavigate();
  const { data, isLoading } = useGetAlbumPageQuery({
    albumId: albumId ?? '',
    eventId: id ?? '',
  });
  const dispatch = useAppDispatch();
  const handleAddToCart = (product: IProduct) => {
    dispatch(
      open({
        title: product.imagesRequired > 1 ? product.name : 'Add to cart',
        contentKey:
          product.imagesRequired > 1
            ? ModalContent.StartMontage
            : ModalContent.AddToCart,
        contentPayload: {
          product: product,
          images: [data?.items[currentImageIndex]],
          albumId,
          eventId: id,
        },
      })
    );
  };
  const handleAddMontageToCart = (product: IProduct) => {
    dispatch(
      open({
        title: 'Add to cart',
        contentKey: ModalContent.AddToCartMultiple,
        contentPayload: {
          product: product,
          images: montage.items,
          albumId,
          eventId: id,
        },
      })
    );
  };
  const montage = useAppSelector(selectMontage);
  const crumbs = [
    { display: 'Gallery', link: '/' },
    ...(data?.crumbs.map(galleryCrumbToDisplayCrumb) ?? []),
    { display: data?.title ?? '', link: '' },
  ];
  return (
    <div
      className={`${styles.page} ${
        layout === 'grid' ? styles.pageGrid : styles.pageSlider
      }`}
    >
      <PageTitle
        className={styles.title}
        title={data?.title}
        backLink={{
          to: crumbs[crumbs.length - 2].link,
          label: crumbs[crumbs.length - 2].display,
        }}
        crumbs={crumbs}
      />

      {isLoading && <Spinner />}
      {!isLoading &&
        data &&
        // eslint-disable-next-line no-constant-condition
        (layout === 'grid' ? (
          <GalleryPage
            className={styles.gallery}
            linkPrefix={{
              album: `/event/${id}`,
            }}
            onItemClicked={(item, index) => {
              if (item.type === 'image') {
                navigate(`slide/${index}`);
              }
            }}
            {...data}
          />
        ) : (
          <>
            {/* <Carousel collection={data.items} collectionTitle={data.title} /> */}
            <Slider2
              slides={data.items}
              defaultIndex={+(index ?? 0)}
              onIndexChange={(index) => {
                setCurrentImageIndex(index);
              }}
            />
            {/* <Slider
              onFavouriteToggle={(item, index) => {
                if (
                  favourites.find(
                    (f) =>
                      f.albumId === albumId &&
                      f.eventId === id &&
                      f.item.id === item.id
                  )
                ) {
                  dispatch(
                    removeFromFavourites({
                      eventId: id ?? '',
                      albumId: albumId ?? '',
                      item,
                    })
                  );
                } else {
                  dispatch(
                    addToFavourites({
                      eventId: id ?? '',
                      albumId: albumId ?? '',
                      item,
                    })
                  );
                }
              }}
              onIndexChanged={(index) => {
                setCurrentImageIndex(index);
              }}
              className={styles.slider}
              defaultIndex={+(index ?? 0)}
              {...(data as GalleryData)}
            /> */}
            <EventProductList
              onAddToCart={handleAddToCart}
              onAddMontageToCart={handleAddMontageToCart}
              onAddImageToMontage={() => {
                dispatch(add(data?.items[currentImageIndex]));
              }}
              eventId={id ?? ''}
              className={styles.products}
            />
          </>
        ))}
    </div>
  );
};
