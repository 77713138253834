import { IEvent, IProduct } from '@topshots/core-models';
import { topshotsAPI } from './api';

const eventsAPI = topshotsAPI.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<IEvent[], void>({
      query: (ids) => `events`,
      providesTags: (result, error, page) =>
        result
          ? [
              // Provides a tag for each enquiry in the current page,
              // as well as the 'PARTIAL-LIST' tag.
              ...result.map(({ _id }) => ({
                type: 'Events' as const,
                id: _id.toString(),
              })),
              { type: 'Events', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Events', id: 'PARTIAL-LIST' }],
    }),
    getEvent: builder.query<IEvent, string>({
      query: (id) => ({
        url: `events/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, page) => [
        { type: 'Events' as const, id: result?._id.toString() },
      ],
    }),
    eventLogin: builder.mutation<
      { success: boolean; eventId: string },
      { eventId: string; password: string }
    >({
      query: ({ eventId, password }) => ({
        url: `events/${eventId}/login`,
        method: 'POST',
        body: { password, eventId },
      }),
    }),
  }),
});

export const { useGetEventQuery, useGetEventsQuery, useEventLoginMutation } =
  eventsAPI;
