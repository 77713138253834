export const HeartFilled = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
  >
    <path
      d="M7.50409 12L1.98727 6.93329C-1.011 3.89326 3.39646 -1.94358 7.50409 2.77859C11.6117 -1.94358 15.9992 3.91353 13.0209 6.93329L7.50409 12Z"
      stroke="#003407"
      fill="#003407"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
