import { IOrder } from '@topshots/core-models';
import { useEffect, useState } from 'react';
import { useCreateOrderMutation, useCurrentUserQuery } from '../app/api/api';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectCartITems } from '../cart/cart.slice';
import { CheckoutTable } from './checkout-table';
import styles from './checkout.module.css';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Button } from '../button/button';
import { CheckoutForm } from './checkout-form';
import AddressForm, { AddressFormValues } from '../address-form/address-form';
import Accordion from '../acordian/acordian';
import { FormGroup } from '../forms/form-group';
import { CheckoutDetailForm, CheckoutDetails } from './checkout-details-form';
import { ModalContent, open } from '../modal/modalSlice';
import { selectIsAuthenticated } from '../login/authSlice';
import { ButtonMode, ButtonTier } from '../button/button.types';
import { useGetProductsQuery } from '../app/api/products';
import { Spinner } from '../spinner/spinner';
import { clearToken } from '../login/authSlice';
import { CheckoutCouponForm } from './checkout-coupon-form';

export const Checkout = () => {
  const cart = useAppSelector(selectCartITems);
  const { data: currentUser, isLoading: loadingUser } = useCurrentUserQuery();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const [createOrder, { isLoading: creatingOrder }] = useCreateOrderMutation();
  const [details, setDetails] = useState<CheckoutDetails>();
  const [couponCode, setCouponCode] = useState<string | undefined>(undefined);
  const [address, setAddress] = useState<AddressFormValues>();
  const [order, setOrder] = useState<IOrder | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const { data: products, isLoading: isLoadingProducts } =
    useGetProductsQuery();
  const addressRequired = products?.find((p) => p.deliveryMechanism === 'post');
  const [stage, setStage] = useState<
    'address' | 'payment' | 'details' | 'account' | 'coupon'
  >(isAuthenticated ? 'details' : 'account');

  return (
    <div className={styles.container}>
      {cart.length > 0 ? (
        loadingUser || isLoadingProducts ? (
          <Spinner />
        ) : (
          <>
            <div className={styles.form}>
              <Accordion
                isOpen={stage === 'account'}
                id="details-section"
                title="1. Your account"
                onTitleClick={() => {
                  if (
                    ['payment', 'address', 'details', 'coupon'].includes(stage)
                  ) {
                    setStage('account');
                  }
                }}
              >
                {isAuthenticated ? (
                  <p>
                    Logged in as {currentUser?.firstName}{' '}
                    {currentUser?.lastName} ({currentUser?.email})&nbsp;
                    <div>
                      <FormGroup className={styles.loggedInButtons}>
                        <Button
                          tier={ButtonTier.Tertiary}
                          onClick={() => {
                            dispatch(clearToken());
                          }}
                        >
                          Log out
                        </Button>

                        <Button
                          onClick={() => {
                            setStage('details');
                          }}
                        >
                          Continue
                        </Button>
                      </FormGroup>
                    </div>
                  </p>
                ) : (
                  <FormGroup className={styles.accountOptions}>
                    <p className={styles.login}>Already have an account?</p>
                    <Button
                      onClick={() => {
                        dispatch(
                          open({
                            title: 'Log in',
                            contentKey: ModalContent.Login,
                          })
                        );
                      }}
                      className={styles.loginButton}
                    >
                      Log in
                    </Button>

                    <p className={styles.or}>---- or ----</p>

                    <p className={styles.guest}>Continue as guest</p>
                    <Button
                      className={styles.guestButton}
                      onClick={() => {
                        setStage('details');
                      }}
                    >
                      Continue
                    </Button>
                    <sub className={styles.guestSubtext}>
                      You can create an account after checkout
                    </sub>
                  </FormGroup>
                )}
              </Accordion>
              <Accordion
                isOpen={stage === 'details'}
                id="details-section"
                title="2. Your details"
                onTitleClick={() => {
                  if (
                    isAuthenticated ||
                    ['payment', 'address', 'coupon'].includes(stage)
                  ) {
                    setStage('details');
                  }
                }}
              >
                <CheckoutDetailForm
                  onSubmit={async (orderDetails) => {
                    setDetails(orderDetails);
                    setStage('coupon');
                  }}
                />
              </Accordion>
              <Accordion
                isOpen={stage === 'coupon'}
                id="coupon-section"
                title="3. Discount code"
                onTitleClick={() => {
                  if (['payment', 'details', 'address'].includes(stage)) {
                    setStage('coupon');
                  }
                }}
              >
                {/* SCASCA */}
                <CheckoutCouponForm
                  onCodeChange={(code) => {
                    setCouponCode(code);
                  }}
                />
                <FormGroup>
                  <Button
                    onClick={async () => {
                      if (!addressRequired) {
                        const o = await createOrder({
                          cart,
                          coupon: couponCode,
                          address: undefined,
                          details: details as CheckoutDetails,
                        }).unwrap();
                        setClientSecret(o.cs);
                        setOrder(o.order);
                        setStage('payment');
                      } else {
                        setStage('address');
                      }
                    }}
                  >
                    Next
                  </Button>
                </FormGroup>
              </Accordion>
              {addressRequired && (
                <Accordion
                  isOpen={stage === 'address'}
                  id="address-section"
                  title="3. Shipping address"
                  onTitleClick={() => {
                    if (['payment', 'details'].includes(stage)) {
                      setStage('address');
                    }
                  }}
                >
                  <AddressForm
                    working={creatingOrder}
                    address={currentUser?.address}
                    onSubmit={async (data) => {
                      setAddress(data);
                      const o = await createOrder({
                        cart,
                        coupon: couponCode,
                        address: data,
                        details: details as CheckoutDetails,
                      }).unwrap();
                      setClientSecret(o.cs);
                      setOrder(o.order);
                      setStage('payment');
                    }}
                    submitText="Next"
                  />
                </Accordion>
              )}
              <Accordion
                isOpen={stage === 'payment'}
                id="payment-section"
                title="4. Payment"
              >
                <div>
                  {clientSecret && (
                    <Elements
                      options={{
                        clientSecret: clientSecret,
                        appearance: {
                          theme: 'night',
                          variables: {
                            colorBackground: '#000000',
                          },
                        },
                      }}
                      stripe={(window as any).stripePromise}
                    >
                      <CheckoutForm orderId={order?._id.toString() ?? ''} />
                    </Elements>
                  )}
                </div>
              </Accordion>
            </div>

            <div className={styles.basket}>
              <h2 className={styles.basketTitle}>Basket</h2>
              <CheckoutTable mode={ButtonMode.Light} coupon={couponCode} />
            </div>
          </>
        )
      ) : (
        <p>Cart is empty</p>
      )}
    </div>
  );
};
