import { GalleryItemProps } from './gallery-item.type';
import styles from './gallery-item.module.css';
import { useNavigate } from 'react-router-dom';
import { GalleryItemPlaceholder } from './gallery-item-placeholder';

export const GalleryItem = ({
  item,
  onItemClicked,
  linkPrefix,
  ...props
}: GalleryItemProps) => {
  const navigate = useNavigate();

  return (
    <div
      {...props}
      onClick={() => {
        switch (item.type) {
          case 'event':
            navigate(`${linkPrefix?.event ?? ''}/event/${item.id}`);
            break;
          case 'album':
            navigate(`${linkPrefix?.album ?? ''}/album/${item.id}`);
            break;
          case 'year':
            navigate(`${linkPrefix?.year ?? ''}/year/${item.id}`);
            break;
          case 'month':
            navigate(`${linkPrefix?.month ?? ''}/month/${item.id}`);
            break;
          case 'image':
            break;

          default:
            console.error(`Unsupported item type: ${item.type}`);
        }
        onItemClicked?.(item);
      }}
      className={styles.galleryItem}
    >
      {item.imageUrl && item.imageUrl !== '' ? (
        <img className={styles.image} src={item.imageUrl} alt={item.id} />
      ) : (
        <GalleryItemPlaceholder />
      )}
      <div className={styles.galleryItemTitle}>{item.title}</div>
    </div>
  );
};
