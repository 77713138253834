import { GalleryItem, IImage, IProduct } from '@topshots/core-models';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { Button } from '../button/button';
import { ButtonIntent, ButtonMode, ButtonTier } from '../button/button.types';
import { Currency } from '../currency/currency';
import { close, ModalContent, open } from '../modal/modalSlice';
import styles from './start-montage-modal.module.css';
import { addToCart } from './cart.slice';
import { selectMontage, start } from './montage.slice';
import { MontageGrid } from './montage-grid';

export const StartMontageModal = ({
  product,
  images,
  albumId,
  eventId,
}: {
  product: IProduct;
  images: GalleryItem[];
  albumId: string;
  eventId: string;
}) => {
  const dispatch = useAppDispatch();
  return (
    <div>
      <p>
        {product.name} requires {product.imagesRequired} images.
      </p>
      <MontageGrid items={images} requiredItems={product.imagesRequired} />
      <div className={styles.buttons}>
        <Button
          tier={ButtonTier.Tertiary}
          className={styles.cancelButton}
          onClick={() => {
            dispatch(close());
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            dispatch(
              start({
                product: product,
                albumId: albumId,
                eventId: eventId,
                item: images[0],
              })
            );
            dispatch(close());
          }}
          className={styles.addButton}
        >
          Start building {product.name}
        </Button>
      </div>
    </div>
  );
};
