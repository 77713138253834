import { GalleryPage } from '@topshots/core-models';
import { topshotsAPI } from './api';

const pagesAPI = topshotsAPI.injectEndpoints({
  endpoints: (builder) => ({
    getCalendarPage: builder.query<GalleryPage, void>({
      query: () => `pages/calendar`,
      providesTags: (result, error, page) => [
        { type: 'GalleryPages', id: 'MAIN_CAL' },
      ],
    }),
    getCalendarYearPage: builder.query<
      GalleryPage,
      {
        year: number;
      }
    >({
      query: (req) => `pages/calendar/${req.year}`,
      providesTags: (result, error, req) => [
        { type: 'GalleryPages', id: `MAIN_CAL_${req.year}` },
      ],
    }),
    getCalendarMonthPage: builder.query<
      GalleryPage,
      {
        year: number;
        month: number;
      }
    >({
      query: (req) => `pages/calendar/${req.year}/${req.month}`,
      providesTags: (result, error, req) => [
        { type: 'GalleryPages', id: `MAIN_CAL_${req.year}_${req.month}` },
      ],
    }),
    getEventPage: builder.query<
      GalleryPage,
      {
        eventId: string;
      }
    >({
      query: (req) => `pages/event/${req.eventId}`,
      providesTags: (result, error, req) => [
        { type: 'GalleryPages', id: `EVENT_${req.eventId}` },
      ],
    }),
    getAlbumPage: builder.query<
      GalleryPage,
      {
        eventId: string;
        albumId: string;
      }
    >({
      query: (req) => `pages/event/${req.eventId}/albums/${req.albumId}`,
      providesTags: (result, error, req) => [
        { type: 'GalleryPages', id: `EVENT_ALBUM_${req.albumId}` },
      ],
    }),
  }),
});

export const {
  useGetCalendarPageQuery,
  useGetCalendarMonthPageQuery,
  useGetCalendarYearPageQuery,
  useGetEventPageQuery,
  useGetAlbumPageQuery,
} = pagesAPI;
