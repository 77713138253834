import { IProduct } from '@topshots/core-models';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { Button } from '../button/button';
import { ButtonTier } from '../button/button.types';
import { MontageGrid } from '../cart/montage-grid';
import { cancel, selectMontage } from '../cart/montage.slice';
import { ProductList } from '../pages/album-page/product-list';
import styles from './event-product-list.module.css';
import { useGetProductsQuery } from '../app/api/products';
import { useGetEventQuery } from '../app/api/events';
import classNames from 'classnames';
import { Spinner } from '../spinner/spinner';

export const EventProductList = ({
  eventId,
  className,
  onAddToCart,
  onAddMontageToCart,
  onAddImageToMontage,
}: {
  eventId: string;
  className: string;
  onAddToCart?: (product: IProduct) => void;
  onAddMontageToCart?: (product: IProduct) => void;
  onAddImageToMontage?: () => void;
}) => {
  const { data: productsData, isLoading: productsIsLoading } =
    useGetProductsQuery();
  const { data: eventData, isLoading: eventIsLoading } =
    useGetEventQuery(eventId);
  const dispatch = useAppDispatch();
  const montage = useAppSelector(selectMontage);
  const digitalProducts = productsData?.filter(
    (p) => p.deliveryMechanism === 'email'
  );
  const physicalProducts = productsData?.filter(
    (p) => p.deliveryMechanism === 'post'
  );
  // eslint-disable-next-line no-constant-condition
  if (productsIsLoading || eventIsLoading) {
    return <Spinner style={{ justifySelf: 'center' }} />;
  }

  return (
    <div className={classNames(className, styles.productList)}>
      {montage.enabled && (
        <>
          <h5>Building {montage.product?.name}</h5>
          <MontageGrid
            allowsDelete={true}
            items={montage.items}
            requiredItems={montage.requiredItems}
          />
          <div className={styles.montageButtons}>
            <Button
              onClick={() => {
                if (
                  //eslint-disable-next-line no-restricted-globals
                  confirm(
                    `Are you sure you want to cancel ${montage.product?.name}?`
                  )
                ) {
                  dispatch(cancel());
                }
              }}
              tier={ButtonTier.Tertiary}
            >
              Cancel {montage.product?.name}
            </Button>
            {montage.items.length === montage.requiredItems ? (
              <Button
                onClick={() => {
                  if (montage.product) {
                    onAddMontageToCart?.(montage.product);
                  }
                }}
              >
                Add to cart
              </Button>
            ) : (
              <Button
                onClick={() => {
                  onAddImageToMontage?.();
                }}
              >
                Add to {montage.product?.name}
              </Button>
            )}
          </div>
        </>
      )}
      {!montage.enabled &&
        physicalProducts &&
        eventData &&
        physicalProducts.length > 0 && (
          <section className={styles.productSection}>
            <h3 className={styles.productGroupTitle}>Mounted prints</h3>
            <ProductList
              event={eventData}
              products={physicalProducts}
              onAddToCart={onAddToCart}
            />
          </section>
        )}
      {!montage.enabled &&
        digitalProducts &&
        eventData &&
        digitalProducts.length > 0 && (
          <section className={styles.productSection}>
            <h3 className={styles.productGroupTitle}>Digital downloads</h3>
            <ProductList
              event={eventData}
              products={digitalProducts}
              onAddToCart={onAddToCart}
            />
          </section>
        )}
    </div>
  );
};
