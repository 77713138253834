import { IProduct } from '@topshots/core-models';
import { topshotsAPI } from './api';

const productsApi = topshotsAPI.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<IProduct[], void>({
      query: (ids) => `products`,
      providesTags: (result, error, page) =>
        result
          ? [
              // Provides a tag for each enquiry in the current page,
              // as well as the 'PARTIAL-LIST' tag.
              ...result.map(({ _id }) => ({
                type: 'Products' as const,
                id: _id.toString(),
              })),
              { type: 'Products', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Products', id: 'PARTIAL-LIST' }],
    }),
    getProduct: builder.query<IProduct, string>({
      query: (id) => ({
        url: `products/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, page) => [
        { type: 'Products' as const, id: result?._id.toString() },
      ],
    }),
    getProductAddOns: builder.query<IProduct[], string>({
      query: (id) => ({
        url: `products/${id}/addons`,
        method: 'GET',
      }),
      providesTags: (result, error, page) =>
        result
          ? [
              // Provides a tag for each enquiry in the current page,
              // as well as the 'PARTIAL-LIST' tag.
              ...result.map(({ _id }) => ({
                type: 'Products' as const,
                id: _id.toString(),
              })),
              { type: 'Products', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Products', id: 'PARTIAL-LIST' }],
    }),
  }),
});

export const {
  useGetProductQuery,
  useGetProductsQuery,
  useGetProductAddOnsQuery,
} = productsApi;
