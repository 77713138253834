import { GalleryItem, IImage, IProduct } from '@topshots/core-models';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { Button } from '../button/button';
import { ButtonIntent, ButtonMode, ButtonTier } from '../button/button.types';
import { Currency } from '../currency/currency';
import { close, ModalContent, open } from '../modal/modalSlice';
import styles from './add-to-cart-modal.module.css';
import { addToCart, selectProductQuantity } from './cart.slice';
import { MontageGrid } from './montage-grid';
import { cancel, selectMontage } from './montage.slice';
import { Icon } from '../svgs';
import { Quant } from '../quant/quant';
import { useGetProductAddOnsQuery } from '../app/api/products';

export const AddToCartModal = ({
  product,
  images,
  albumId,
  eventId,
}: {
  product: IProduct;
  images: GalleryItem[];
  albumId: string;
  eventId: string;
}) => {
  const { data: addOns, isLoading: addOnsLoading } = useGetProductAddOnsQuery(
    product._id.toString()
  );
  const montage = useAppSelector(selectMontage);
  const handleAddToCart = () => {
    dispatch(
      addToCart([
        {
          productId: product._id.toString(),
          quantity: quantity,
          albumId: albumId,
          eventId: eventId,
          imageIds: images.map((i) => i.id),
          addOnIds: selectedAddOns.map((ao) => ao._id.toString()),
        },
      ])
    );
    if (montage.enabled) {
      dispatch(cancel());
    }
    dispatch(open({ title: 'Your cart', contentKey: ModalContent.Cart }));
  };
  const dispatch = useAppDispatch();
  const [quantity, setQuantity] = useState<number>(1);
  const [selectedAddOns, setSelectedAddOns] = useState<IProduct[]>([]);
  const cartQuant = useAppSelector(
    selectProductQuantity(product._id.toString())
  );
  let unitPrice = {
    discounted: false,
    from: product?.price ?? 100,
    to: product?.price ?? 100,
  };
  switch (product?.pricingModel) {
    case 'tiered':
      // eslint-disable-next-line no-case-declarations
      const tier = product.priceTiers.find(
        (tp) =>
          tp.minVolume <= cartQuant + 1 &&
          (tp.maxVolume == null || tp.maxVolume >= cartQuant)
      );
      unitPrice = {
        discounted: tier?.price !== product.priceTiers[0].price,
        from: product.priceTiers[0].price,
        to: tier?.price ?? product.price,
      };
      break;
  }
  return (
    <div className={styles.item}>
      {images.length > 1 ? (
        <MontageGrid
          className={styles.imageGrid}
          requiredItems={images.length}
          items={images}
        />
      ) : (
        <img
          alt={images[0].title}
          className={styles.image}
          src={images[0].imageUrl}
        />
      )}

      <span className={styles.name}>{product.name}</span>

      {/* <Currency value={product.price} className={styles.price} /> */}
      <Currency
        value={unitPrice.discounted ? unitPrice.to : unitPrice.from}
        className={styles.price}
      />
      <Quant
        onChange={(newQuantity) => {
          setQuantity(newQuantity);
        }}
      />

      <div className={styles.addOns}>
        {addOns && addOns.length > 0 && (
          <>
            <h4 className={styles.addOnsTitle}>Upgrade your order</h4>
            <ul className={styles.addOnsList}>
              {addOns.map((a) => (
                <li key={a._id.toString()} className={styles.addOn}>
                  <input
                    id={a._id.toString()}
                    defaultChecked={false}
                    type="checkbox"
                    onChange={(evt) => {
                      if (evt.target.checked) {
                        setSelectedAddOns([...selectedAddOns, a]);
                      } else {
                        setSelectedAddOns(
                          selectedAddOns.filter((s) => s._id !== a._id)
                        );
                      }
                    }}
                  />
                  <label htmlFor={a._id.toString()}>{a.name}</label>
                  <Currency value={a.price} />
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div className={styles.buttons}>
        <Button
          tier={ButtonTier.Secondary}
          className={styles.cancelButton}
          onClick={() => {
            dispatch(close());
          }}
        >
          Cancel
        </Button>
        <Button onClick={handleAddToCart} className={styles.addButton}>
          Add to cart
        </Button>
      </div>
    </div>
  );
};
