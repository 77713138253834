import styles from './app.module.css';

import { Route, Routes, useLocation } from 'react-router-dom';
import { YearsPage } from '../pages/years-page/years-page';
import { YearPage } from '../pages/year-page/year-page';
import { MonthPage } from '../pages/month-page/month-page';
import { EventPage } from '../pages/event-page/event-page';
import { AlbumPage } from '../pages/album-page/album-page';
import { CheckoutPage } from '../pages/checkout-page/checkout-page';
import { CheckoutComplete } from '../checkout/checkout-complete';
import { Footer } from '../footer/footer';
import { Header } from '../header/header';
import { useAppDispatch, useAppSelector } from './hooks';
import { selectModal } from '../modal/modalSlice';
import { FavouritesPage } from '../pages/favourites-page/favourites-page';
import { useCurrentUserQuery } from './api/api';
import { useEffect } from 'react';
import { close, selectNav } from '../header/nav/navSlice';
import classNames from 'classnames';
import { AccountPage } from '../pages/account-page/account-page';
import { PrivacyPage } from '../pages/privacy-page/privacy-page';
import { ResetPassword } from '../reset-password/reset-password';
import { CopyrightPage } from '../pages/copyright-page/copyright-page';
import { CookiePolicyPage } from '../pages/cookie-policy-page/cookie-policy-page';

export function App() {
  const modalData = useAppSelector(selectModal);
  const navData = useAppSelector(selectNav);
  const dispatch = useAppDispatch();
  const { data, isFetching, isLoading } = useCurrentUserQuery();
  const location = useLocation();

  useEffect(() => {
    dispatch(close());
  }, [location]);

  return (
    <div
      className={classNames(styles.app, {
        [styles.modalOpen]: modalData.isOpen,
      })}
    >
      <Header />
      <div className={styles.main}>
        <Routes>
          <Route path="/" element={<YearsPage />} />
          <Route path="/year/:year" element={<YearPage />} />
          <Route path="/year/:year/month/:month" element={<MonthPage />} />
          <Route path="/event/:id" element={<EventPage />} />
          <Route path="/event/:id/album/:albumId" element={<AlbumPage />} />
          <Route
            path="/event/:id/album/:albumId/slide/:index"
            element={<AlbumPage layout="slider" />}
          />
          <Route path="/favourites" element={<FavouritesPage />} />
          <Route
            path="/favourites/slide/:index"
            element={<FavouritesPage layout="slider" />}
          />
          <Route
            path="/checkout/complete/:orderId"
            element={<CheckoutComplete />}
          />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/cookies" element={<CookiePolicyPage />} />
          <Route path="/copyright" element={<CopyrightPage />} />
          <Route
            path="/reset-password/:token"
            element={<YearsPage resetPassword={true} />}
          />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
