import { useGetCouponStateQuery } from '../app/api/api';
import { Button } from '../button/button';
import { ButtonTier } from '../button/button.types';
import { Spinner } from '../spinner/spinner';
import styles from './checkout-coupon-applied.module.css';

export const CheckoutCouponApplied = ({
  code,
  onClear,
}: {
  code?: string;
  onClear: () => void;
}) => {
  const { data, isLoading, error } = useGetCouponStateQuery(code ?? '');
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className={styles.discountCode}>
      {!data?.isValid ? (
        error &&
        ((error as any)?.status === 404 ||
          (error as any)?.status === 'PARSING_ERROR') ? (
          <p>Code '{code}' is not valid.</p>
        ) : (
          <p>Code '{code}' is no longer valid.</p>
        )
      ) : (
        <p>Applied code '{code}'.</p>
      )}
      <Button
        onClick={() => {
          onClear();
        }}
        tier={ButtonTier.Secondary}
      >
        Remove
      </Button>
    </div>
  );
};
