import { useFormik } from 'formik';
import { ErrorText } from '../../forms/error-text';
import { FormGroup } from '../../forms/form-group';
import * as Yup from 'yup';
import { Button } from '../../button/button';
import { useEventLoginMutation } from '../../app/api/events';
import { useState } from 'react';

export const EventLoginPage = ({ eventId }: { eventId: string }) => {
  const DetailsSchema = Yup.object({
    password: Yup.string().required('Please enter a password'),
  });
  const [login, { isLoading, isSuccess, isError }] = useEventLoginMutation();
  const [subError, setSubError] = useState<string | null>(null);
  const { getFieldProps, isSubmitting, touched, errors, handleSubmit } =
    useFormik({
      initialValues: {
        password: '',
      },
      enableReinitialize: true,
      validationSchema: DetailsSchema,
      onSubmit: async (values, { setSubmitting }) => {
        // handle form submission here
        const res = await login({ eventId, password: values.password });
        if ((res as any).data.success) {
          setSubError(null);
          window.location.reload();
        } else {
          setSubError('Invalid password');
        }
      },
    });

  return (
    <div>
      <p>
        This event is protected by a password, please enter the password to
        access the event
      </p>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <label htmlFor="password">Password</label>
          <input type="password" id="password" {...getFieldProps('password')} />
          {touched.password && errors.password ? (
            <ErrorText>{errors.password}</ErrorText>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Button type="submit" disabled={isSubmitting}>
            Enter
          </Button>
        </FormGroup>
        {subError && <ErrorText>{subError}</ErrorText>}
      </form>
    </div>
  );
};
