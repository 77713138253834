import { useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetOrderSummaryQuery, useRegisterMutation } from '../app/api/api';
import styles from './checkout-complete-details.module.css';
import { Spinner } from '../spinner/spinner';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { clearCart } from '../cart/cart.slice';
import { selectIsAuthenticated } from '../login/authSlice';
import { Card } from '../card/card';
import { Button } from '../button/button';
import { ModalContent, open } from '../modal/modalSlice';

export const CheckoutCompleteDetails = () => {
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  // const [
  //   register, // This is the mutation trigger
  //   { isLoading: isRegistering }, // This is the destructured mutation result
  // ] = useRegisterMutation();
  const [pollingInterval, setPollingInterval] = useState(1000);
  const { orderId } = useParams<{ orderId: string }>();
  const {
    data: orderSummary,
    error: summaryError,
    isLoading: summaryLoading,
  } = useGetOrderSummaryQuery(orderId ?? '', {
    pollingInterval,
  });
  useEffect(() => {
    dispatch(clearCart());
  });
  useEffect(() => {
    if (orderSummary) {
      if (
        !orderSummary?.items?.downloads.find(
          (d) => !d.ready && d.isInstantDownload
        )
      ) {
        setPollingInterval(0);
      }
    }
  }, [orderSummary]);
  const stripe = useStripe();
  const [message, setMessage] = useState<string>();
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage(
            'Your payment has been successful, and your order is complete.'
          );
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  if (summaryError) {
    if ((summaryError as any)?.originalStatus === 401) {
      return (
        <div>
          {/* <h1 className={styles.mainTitle}>
            Login to your account to view your orders
          </h1> */}
          <Card>
            <h2 className="m-t-0">Login to your account to view your orders</h2>
            <p>To view your order, log into your account.</p>
            <Button
              onClick={() => {
                dispatch(
                  open({
                    title: 'Login',
                    contentKey: ModalContent.Login,
                    contentPayload: {
                      redirect: `/checkout/complete/${orderId}`,
                    },
                  })
                );
              }}
            >
              Login
            </Button>
          </Card>
        </div>
      );
    } else {
      nav('/');
      return null;
    }
  }

  return (
    <div>
      <h1 className={styles.mainTitle}>Checkout complete!</h1>

      {message && <p className={styles.processMessage}>{message}</p>}
      {!isAuthenticated && (
        <Card>
          <h2 className="m-t-0">Create an account</h2>
          <p>
            To track your order, see your purchase history and speed up future
            purchases, create an account now.
          </p>
          <Button
            onClick={() => {
              dispatch(
                open({
                  title: 'Create an account',
                  contentKey: ModalContent.Register,
                  contentPayload: {
                    orderId,
                  },
                })
              );
            }}
          >
            Create an account
          </Button>
        </Card>
      )}
      <div className={styles.orderSummary}>
        <h2>Your order summary</h2>
        {(orderSummary?.items?.downloads.length ?? 0) > 0 && (
          <>
            <h3>Digital downloads</h3>
            <p>
              The following parts of your order are digital downloads and will
              be available to download here shortly.
            </p>

            <div className={styles.downloadsList}>
              {orderSummary &&
                orderSummary?.items?.downloads.map((d) => (
                  <div className={styles.download}>
                    {d.ready ? (
                      <a
                        className={styles.link}
                        download={true}
                        href={d.downloadUrl}
                      >
                        <img
                          alt={d.image.name}
                          className={styles.img}
                          src={d.image.url}
                        />
                        <span>Download</span>
                      </a>
                    ) : (
                      <>
                        <img
                          alt={d.image.name}
                          className={styles.img}
                          src={d.image.url}
                        />
                        <p>{d.productName}</p>
                        <p>{d.image.name}</p>
                        <span className={styles.generating}>
                          {d.isInstantDownload ? (
                            <>
                              <span>Status: Generating... &nbsp;</span>
                              <Spinner size="small" />
                            </>
                          ) : (
                            <span>
                              We're getting your download ready, it will be with
                              you soon.
                            </span>
                          )}
                        </span>
                      </>
                    )}
                  </div>
                ))}
            </div>
          </>
        )}
        {(orderSummary?.items?.prints.length ?? 0) > 0 && (
          <>
            <h3>Physical prints</h3>
            <p>Physical prints will be shipped to the address you provided.</p>
            <div className={styles.downloadsList}>
              {orderSummary?.items?.prints.map((d) => (
                <div className={styles.print}>
                  <div
                    className={
                      (d.images?.length ?? 1) > 1 ? styles.images : styles.image
                    }
                  >
                    {d.images.map((i) => (
                      <img alt={i.name} className={styles.img} src={i.url} />
                    ))}
                  </div>
                  <ul className={styles.summaryImageNameList}>
                    {d.images.map((i) => (
                      <li>{i.name}</li>
                    ))}
                  </ul>
                  <p>{d.productName}</p>
                  <p>Status: {d.shipped ? 'Shipped' : 'Shipping soon'}</p>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
