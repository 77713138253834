import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { topshotsAPI } from '../app/api/api';
import { RootState } from '../app/store';

export interface AuthState {
  token?: string;
}

const initialState: AuthState = {
  token: localStorage.getItem('AUTH_TOKEN') ?? undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem('AUTH_TOKEN', action.payload);
    },
    clearToken: (state) => {
      state.token = undefined;
      logout();
    },
  },
  extraReducers: (builder) => {
    // builder.addMatcher(
    //   topshotsAPI.endpoints.currentUser.matchFulfilled,
    //   (state, { payload }) => {
    //     if (payload == null) {
    //       state.token = undefined;
    //       logout();
    //     }
    //   }
    // );
  },
});

export const logout = () => {
  localStorage.removeItem('AUTH_TOKEN');
};

export const { setToken, clearToken } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectToken = (state: RootState) => state.auth.token;
export const selectIsAuthenticated = (state: RootState) =>
  state.auth.token !== undefined;

export default authSlice.reducer;
