import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  GalleryPage,
  IAlbum,
  IEvent,
  IImage,
  IOrder,
  IOrderItemsSummary,
  IOrderSummary,
  IProduct,
  ISiteSettings,
  IUser,
} from '@topshots/core-models';
import { url } from 'inspector';
import { AddressFormValues } from '../../address-form/address-form';
import { CartItem } from '../../cart/cart.types';
import { CheckoutDetails } from '../../checkout/checkout-details-form';
import { clearToken, logout } from '../../login/authSlice';
import { UserSession } from '../../login/login.types';
import { RootState, store } from '../store';

const authHandlingBaseQuery = () => {
  const base = fetchBaseQuery({
    baseUrl: `${process.env.NX_TOPSHOTS_BASE_URL ?? ''}/api/gallery/`,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  });
  return async (args: any, api: any, extraOptions: any) => {
    const { error, data } = await base(args, api, extraOptions);
    if (error) {
      if ((error as any | unknown)?.originalStatus === 401) {
        store.dispatch(clearToken());
      }
      return {
        error: {
          status: error.status,
          originalStatus: (error as any | unknown)?.originalStatus,
          data: error.data,
        },
      };
    }

    return { data };
  };
};

export const topshotsAPI = createApi({
  reducerPath: 'topshotsAPI',
  tagTypes: [
    'GalleryPages',
    'EventProducts',
    'SETTINGS',
    'Image',
    'User',
    'Products',
    'Events',
  ],
  baseQuery: authHandlingBaseQuery(),
  endpoints: (builder) => ({
    getSettings: builder.query<Pick<ISiteSettings, 'currency'>, void>({
      query: () => `settings`,
      providesTags: (result, error, page) => ['SETTINGS'],
    }),
    getImage: builder.query<
      {
        id: string;
        url: string;
        event: IEvent;
        album: IAlbum;
        name: string;
      },
      {
        id: string;
      }
    >({
      query: (req) => `images/${req.id}`,
      providesTags: (result, error, page) => [
        { type: 'Image', id: result?.id },
      ],
    }),
    getImages: builder.query<
      {
        id: string;
        url: string;
        event: IEvent;
        album: IAlbum;
        name: string;
      }[],
      {
        ids: string[];
      }
    >({
      query: ({ ids }) => ({
        url: `images/`,
        params: { ids },
        method: 'GET',
      }),
      providesTags: (result, error, page) =>
        result ? result.map((r) => ({ type: 'Image', id: r?.id })) : [],
    }),
    getCheckoutTotal: builder.query<
      { total: number; shipping: number; discount: number },
      { cart: CartItem[]; coupon?: string }
    >({
      query: (items) => ({
        url: 'checkout/total',
        method: 'POST',
        body: items,
      }),
    }),
    createOrder: builder.mutation<
      {
        order: IOrder;
        cs: string;
      },
      {
        cart: CartItem[];
        coupon?: string;
        address?: AddressFormValues;
        details: CheckoutDetails;
      }
    >({
      query: (items) => ({
        url: 'checkout',
        method: 'POST',
        body: items,
      }),
    }),
    login: builder.mutation<UserSession, { email: string; password: string }>({
      query: (request) => ({
        url: 'login',
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['User'],
    }),
    forgottenLogin: builder.mutation<void, { email: string }>({
      query: (request) => ({
        url: 'login/forgotten',
        method: 'POST',
        body: request,
      }),
    }),
    resetLogin: builder.mutation<
      { email: string },
      {
        password: string;
        duplicatePassword: string;
        token: string;
      }
    >({
      query: (request) => ({
        url: 'login/reset',
        method: 'POST',
        body: request,
      }),
    }),
    register: builder.mutation<
      UserSession,
      { email: string; password: string; orderId?: string }
    >({
      query: (request) => ({
        url: 'register',
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['User'],
    }),
    currentUser: builder.query<IUser, void>({
      query: () => 'users',
      //providesTags: ['User'],
    }),
    getOrderSummary: builder.query<IOrderSummary, string>({
      query: (id) => `orders/${id}/summary`,
    }),
    getOrderSummaries: builder.query<IOrderSummary[], void>({
      query: () => `orders/`,
    }),
    getCouponState: builder.query<
      {
        code: string;
        isValid: boolean;
      },
      string
    >({
      query: (couponCode) => `coupons/${couponCode}`,
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useGetImageQuery,
  useGetImagesQuery,
  useGetCheckoutTotalQuery,
  useCreateOrderMutation,
  useCurrentUserQuery,
  useLoginMutation,
  useForgottenLoginMutation,
  useResetLoginMutation,
  useGetOrderSummaryQuery,
  useRegisterMutation,
  useGetOrderSummariesQuery,
  useGetCouponStateQuery,
} = topshotsAPI;
