export const IncreaseArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="22"
    viewBox="0 0 12 22"
    fill="none"
  >
    <path
      d="M11.5104 10.5104C11.9009 10.9009 11.9009 11.5341 11.5104 11.9246L1.81592 21.6191C1.18595 22.2491 0.108812 21.8029 0.108812 20.912V1.523C0.108812 0.632093 1.18595 0.185923 1.81592 0.815888L11.5104 10.5104Z"
      fill="#83BD5E"
    />
  </svg>
);
