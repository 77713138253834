import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GalleryItem } from '@topshots/core-models';
import { RootState } from '../app/store';

export interface FavouritesState {
  favourites: {
    item: GalleryItem;
    albumId: string;
    eventId: string;
  }[];
}

const initialState: FavouritesState = {
  favourites: [],
};

export const favouritesSlice = createSlice({
  name: 'favourites',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addToFavourites: (
      state,
      action: PayloadAction<{
        item: GalleryItem;
        albumId: string;
        eventId: string;
      }>
    ) => {
      state.favourites.push(action.payload);
    },
    removeFromFavourites: (
      state,
      action: PayloadAction<{
        item: GalleryItem;
        albumId: string;
        eventId: string;
      }>
    ) => {
      state.favourites = state.favourites.filter(
        (f) =>
          !(
            f.item.id === action.payload.item.id &&
            f.albumId === action.payload.albumId &&
            f.eventId === action.payload.eventId
          )
      );
    },
  },
});

export const { addToFavourites, removeFromFavourites } =
  favouritesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectFavourites = (state: RootState) =>
  state.favourites.favourites;

export default favouritesSlice.reducer;
