import { useState } from 'react';
import { FormGroup } from '../forms/form-group';
import styles from './checkout-coupon-form.module.css';
import { ButtonTier } from '../button/button.types';
import { Button } from '../button/button';
import { CheckoutCouponApplied } from './checkout-coupon-applied';

export const CheckoutCouponForm = ({
  onCodeChange,
}: {
  onCodeChange: (code?: string) => void;
}) => {
  const [couponCode, setCouponCode] = useState<string | undefined>(undefined);
  const [_couponCode, _setCouponCode] = useState<string | undefined>(undefined);
  const updateCode = (code?: string) => {
    setCouponCode(code);
    onCodeChange(code);
  };
  if (couponCode && couponCode !== '') {
    return (
      <CheckoutCouponApplied
        code={couponCode}
        onClear={() => {
          updateCode(undefined);
        }}
      />
    );
  }
  return (
    <FormGroup className={styles.discountCode}>
      <label htmlFor="discountCode">Discount code</label>
      <input
        style={{
          gridArea: 'input',
        }}
        type="text"
        id="discountCode"
        onChange={(ev) => {
          _setCouponCode(ev.target.value);
        }}
      />
      <Button
        style={{
          gridArea: 'button',
        }}
        tier={ButtonTier.Secondary}
        onClick={() => {
          if (_couponCode && _couponCode.length > 0) {
            updateCode(_couponCode);
          }
        }}
      >
        Apply
      </Button>
    </FormGroup>
  );
};
